import React, { useEffect, useState } from 'react'
import { Images } from '../theme'

export default function SpecificationCard({ spec }) {

  const [hover, setHover] = useState(false)
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    checkMobile()
    window.addEventListener('resize', checkMobile)

    return () => {
        window.removeEventListener('resize', checkMobile)
    }
  }, [])

  const checkMobile = () => {
    if (window.innerWidth <= 1024) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  return (
    <div className='specification-card' 
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p className='spec-name'>{spec.name}</p>
      <img src={hover || isMobile ? (Images?.[`${spec.key}_white`] ?? Images.test_active) : (Images?.[spec.key] ?? Images.test)} />
      <p className='spec-value'>{spec.value}</p>
    </div>
  )
}
