import React, { useEffect } from "react";
import { BsWhatsapp } from "react-icons/bs";
import ContactButton from "../components/ContactButton";
import { object, string } from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import analytics from "../firebase";

const CONTACT_PHONE = "40765754553";

const INITIAL_VALUES = { name: "", city: "", phone: "", message: "" };

const MESSAGE_MAX_LENGTH = 300;

export default function Contact() {
	const { t } = useTranslation();
	const formSchema = object({
		name: string()
			.required(t("contact.nameReq"))
			.min(2, t("contact.tooShort"))
			.max(30, t("contact.tooLong")),
		city: string()
			.required(t("contact.cityReq"))
			.min(2, t("contact.tooShort"))
			.max(30, t("contact.tooLong")),
		phone: string()
			.required(t("contact.phoneReq"))
			.test("validatePhone", t("contact.invalidPhone"), (value) => {
				return (
					value
						.replace(/\s/g, "")
						.match(
							/^\+?([0-9]{1,3})?[-. ]?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
						) ||
					value
						.replace(/\s/g, "")
						.match(
							/^\+?([0-9]{1,3})?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
						)
				);
			})
			.max(15, t("contact.tooLong")),
		message: string().max(MESSAGE_MAX_LENGTH, t("contact.messageTooLong")),
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleSubmit = (values, resetForm) => {
		logEvent(analytics, `open_wapp_contact`);
		openWhatsApp(values);
		resetForm();
	};

	const openWhatsApp = (inputs) => {
		const message = Object.values(inputs)
			.map((item) => item)
			.join("\n");
		const encodedMessage = encodeURI(message);
		const url = isMobile
			? `whatsapp://send?phone=${CONTACT_PHONE}&text=${encodedMessage}`
			: `https://wa.me/${CONTACT_PHONE}?text=${encodedMessage}&app_absent=0`;

		window.open(url);
	};

	return (
		<div className='contact-container'>
			<Formik
				initialValues={INITIAL_VALUES}
				validationSchema={formSchema}
				onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
			>
				{(formik) => {
					const { errors, touched } = formik;

					return (
						<Form className='contact-form'>
							<div className='contact-form-header'>
								<h1>{t("interface.contactUs")}</h1>
								<BsWhatsapp size={30} color={"#25D366"} />
							</div>

							<p className='input-title'>{`${t("contact.name")}*`}</p>
							<Field
								name='name'
								id='name'
								placeholder={`${t("contact.name")}*`}
								className={errors.name && touched.name ? "input-error" : null}
							/>
							{errors.name && touched.name ? (
								<p className='error'>{errors.name}</p>
							) : (
								<></>
							)}

							<p className='input-title'>{`${t("contact.city")}*`}</p>
							<Field
								name='city'
								id='city'
								placeholder={`${t("contact.city")}*`}
								className={errors.city && touched.city ? "input-error" : null}
							/>
							{errors.city && touched.city ? (
								<p className='error'>{errors.city}</p>
							) : (
								<></>
							)}

							<p className='input-title'>{`${t("contact.phone")}*`}</p>
							<Field
								name='phone'
								id='phone'
								placeholder={`${t("contact.phone")}*`}
								className={errors.phone && touched.phone ? "input-error" : null}
							/>
							{errors.phone && touched.phone ? (
								<p className='error'>{errors.phone}</p>
							) : (
								<></>
							)}

							<p className='input-title'>{t("contact.message")}</p>
							<Field
								name='message'
								id='message'
								placeholder={t("contact.messagePlaceholder")}
								as={"textarea"}
								rows='10'
								className={errors.message ? "input-error" : null}
							/>
							{errors.message ? (
								<p className='error'>{errors.message}</p>
							) : (
								<></>
							)}
							<p className='length-text'>
								{formik.values.message.length}/{MESSAGE_MAX_LENGTH}
							</p>

							<ContactButton text={t("interface.sendMessage")} type='submit' />
						</Form>
					);
				}}
			</Formik>
		</div>
	);
}
