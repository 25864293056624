import React, { useEffect } from 'react'
import ContactButton from '../components/ContactButton'
import { Images } from '../theme'
import { useTranslation } from 'react-i18next'

export default function Careers() {

  const { t } = useTranslation()
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='careers-container'>
      <div className='secondary-page-hero'>
        <h1>{t('header.careers')}</h1>
        <h3>{t('careers.pitchPhrase')}</h3>
        <ContactButton text={t('interface.contactUs')} />
      </div>

      <div className='container' id='careers-team'>
        <img src={Images.careers} />
        
        <div className='careers-details'>
          <p>{t('careers.details')}</p>
          {/* <ul className='career-list'>
            <li>Peste 40 de colegi</li>
            <li>Utilaje performante</li>
            <li>Atmosfera relaxata</li>
            <li>Etc</li>
            <li>Etc</li>
          </ul> */}
        </div>
      </div>

      <div className='secondary-page-hero'>
        <div id='of-req-container'>
          <div className='careers-offer-request' id='offer'>
            <h2>{t('careers.offer')}</h2>
            <ul className='career-list'>
              {
                t('careers.offerList').map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              }
            </ul>
          </div>

          <div className='vertical-line-container'>
            <div className='vertical-line'/>
          </div>

          <div className='careers-offer-request' id='requests'>
            <h2>{t('careers.expectation')}</h2>
            <ul className='career-list'>
              {
                t('careers.expectationList').map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
