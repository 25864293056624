import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import SpecificationCard from "../components/SpecificationCard";
import { pathToTranslation } from "../utils/helpers";
import { Images } from "../theme";

export default function Product({}) {
	const { t } = useTranslation();
	const location = useLocation();
	const [transKey, setTransKey] = useState("pvc_alu.windows.rehau");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		let tempKey = pathToTranslation(location.pathname);
		setTransKey(tempKey);
	}, [location]);

	useEffect(() => {
		const handleInitialHash = () => {
			const hash = window.location.hash;
			if (hash) {
				setTimeout(() => {
					const element = document.getElementById(hash.replace("#", ""));
					if (element) {
						element.scrollIntoView({ behavior: "smooth" });
					}
				}, 100); // Delay to ensure the element is rendered
			}
		};
		handleInitialHash();
	}, []);

	// Get the items from the translation data
	const items = t(`products.${transKey}.items`);

	return (
		<div className='products-wrapper container'>
			{!Array.isArray(items) ? ( // Check if items is an array before rendering to prevent the reloading error (it happened because of wrong translation file for the default language..., so it's not necessary anymore)
				<Loader />
			) : (
				items.map((item, index) => (
					<div
						className={`product-container ${
							index % 2 === 1 ? "product-container-mirrored" : ""
						} ${item?.image ? "product-with-image-container" : ""}`}
						key={index}
						id={`${item.name.split(" ").join("-")}`}
					>
						<div
							className={`${
								item?.image
									? `product-container ${
											index % 2 === 1 ? "product-container-mirrored" : ""
									  }`
									: ""
							}`}
						>
							<div className='product-description-container'>
								<h2 className='product-title'>
									<a href={`#${item.name.split(" ").join("-")}`}>{item.name}</a>
								</h2>
								<p className='product-description'>{item.description}</p>
								{item?.list ? (
									<ul>
										{item.list.map((item) => (
											<li>{item}</li>
										))}
									</ul>
								) : (
									<></>
								)}
							</div>

							{item?.image ? (
								<img
									src={Images.products[item.image]}
									style={{ maxWidth: 350, maxHeight: 350, alignSelf: "center" }}
								/>
							) : (
								<></>
							)}
						</div>
						<div className='product-specification-container'>
							{item.specifications.map((spec, index) => (
								<SpecificationCard spec={spec} key={`${index}-${spec.value}`} />
							))}
						</div>
					</div>
				))
			)}
		</div>
	);
}
