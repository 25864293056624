import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/Router.js";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function App() {
	const { t } = useTranslation();

	return (
		<HelmetProvider>
			<Helmet>
				<title>{t("tags.title")}</title>
				<meta name='description' content={t("tags.description")} />
				<meta property='og:type' content='website' />
				<meta property='og:url' content='thermoartdesign.ro' />
				<meta property='og:title' content={t("tags.title")} />
				<meta property='og:title' content={t("tags.description")} />
				<meta
					property='og:image'
					content='https://thermoartdesign.ro/static/media/tamplarie-pvc.2a1b368a5f61d8f371ae.jpg'
				/>
				<meta name='twitter:card' content='summary_large_image' />
				<meta
					name='twitter:image'
					content='https://thermoartdesign.ro/static/media/tamplarie-pvc.2a1b368a5f61d8f371ae.jpg'
				/>
				<meta name='twitter:title' content={t("tags.title")} />
				<meta name='twitter:description' content={t("tags.description")} />
			</Helmet>
			<RouterProvider router={router} />
		</HelmetProvider>
	);
}

export default App;
