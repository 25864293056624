import React from "react";
import { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import ContactButton from "./ContactButton.js";
import { COLORS, Images } from "../theme/index.js";
import { ImFacebook2 as FacebookIcon } from "react-icons/im";
import { BsInstagram as InstagramIcon } from "react-icons/bs";
import { AiOutlineMail as MailIcon } from "react-icons/ai";
import { BsTelephone as PhoneIcon } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function Footer() {
	const { t } = useTranslation();
	const [contactType, setContactType] = useState(0);
	const location = useLocation();
	const year = new Date().getFullYear();

	useEffect(() => {
		const path = location.pathname;
		if (path === "/" || path.includes("pvc-alu") || path.includes("wood")) {
			setContactType(1);
		} else if (path.includes("careers")) {
			setContactType(2);
		} else if (path.includes("partners")) {
			setContactType(3);
		} else {
			setContactType(0);
		}
	}, [location]);

	const getText = () => {
		switch (contactType) {
			case 1:
				return t("footer.findOutMore");
			case 2:
				return t("footer.partOfTeam");
			case 3:
				return t("footer.ourPartner");
			default:
				return t("footer.findOutMore");
		}
	};

	const renderContact = () => {
		if (contactType === 0) {
			return <></>;
		} else {
			return (
				<div className='container footer-contact-section'>
					<p>{getText()}</p>
					<ContactButton text={t("interface.contactUs")} />
				</div>
			);
		}
	};

	return (
		<footer>
			{renderContact()}

			<div className='footer-link-section'>
				<div className='footer-column' id='footer-logo-container'>
					<Link to='/'>
						<img src={Images.logo_white} alt={"ThermoArt Design logo"} />
					</Link>
				</div>

				<div className='footer-column' id='footer-sitemap'>
					<div className='subsection-container'>
						<h4>{t("footer.discoverUs")}</h4>

						<div className='subsection-1'>
							<div className='subsection-2'>
								<Link to='/'>{t("header.home")}</Link>
								<Link to='careers'>{t("header.careers")}</Link>
								<Link to='partners'>{t("header.partners")}</Link>
								<Link to='contact'>{t("header.contact")}</Link>
							</div>
						</div>
					</div>

					<div className='subsection-container'>
						<h4>{t("header.pvcAluminum")}</h4>

						<div className='subsection-1'>
							<div className='subsection-2'>
								<h5>{t("header.windows")}</h5>
								<NavLink to='pvc-alu/windows/gealan'>Gealan</NavLink>
								<NavLink to='pvc-alu/windows/rehau'>Rehau</NavLink>
							</div>
							<div className='subsection-2'>
								<h5>{t("header.doors")}</h5>
								<NavLink to='pvc-alu/pvc-doors/exterior'>
									{t("header.exteriorDoors")}
								</NavLink>
								<NavLink to='pvc-alu/pvc-doors/interior'>
									{t("header.interiorDoors")}
								</NavLink>
								<NavLink to='pvc-alu/pvc-doors/sliding'>
									{t("header.slidingDoors")}
								</NavLink>
							</div>
							<div className='subsection-2'>
								<h5>{t("header.others")}</h5>
								<NavLink to='pvc-alu/glass'>{t("header.glass")}</NavLink>
								<NavLink to='pvc-alu/rollers'>{t("header.rollers")}</NavLink>
								<NavLink to='pvc-alu/garage-doors'>
									{t("header.garageDoors")}
								</NavLink>
							</div>
							<div className='subsection-2'>
								<h5>{t("header.aluminum")}</h5>
								<NavLink to='pvc-alu/aluminum-joinery'>
									{t("header.aluminumJoinery")}
								</NavLink>
							</div>
						</div>
					</div>

					<div className='subsection-container'>
						<h4>{t("header.furnitureDoors")}</h4>

						<div className='subsection-1'>
							<div className='subsection-2'>
								<h5>{t("header.furniture")}</h5>
								<NavLink to='wood/furniture/mdf'>
									{t("header.mdfFurniture")}
								</NavLink>
								<NavLink to='wood/furniture/pal'>
									{t("header.palFurniture")}
								</NavLink>
							</div>
							<div className='subsection-2'>
								<h5>{t("header.doors")}</h5>
								<NavLink to='wood/doors/wood'>{t("header.woodDoors")}</NavLink>
								<NavLink to='wood/doors/mdf'>{t("header.mdfDoors")}</NavLink>
							</div>
						</div>
					</div>
				</div>

				<div className='footer-column' id='company-details'>
					<div className='subsection-container'>
						<h4>{t("footer.followUs")}</h4>

						<a
							className='contact-row'
							href='https://www.facebook.com/Ferestre.Usi.Pvc.Mobila.Lemn.Masiv.La.Comanda/'
							target='_blank'
						>
							<FacebookIcon color={COLORS.white} size={18} />
							<p>ThermoArt Design Ferestre & Usi PVC - lemn masiv</p>
						</a>
						<a
							className='contact-row'
							href='https://www.instagram.com/thermoartdesign/'
							target='_blank'
						>
							<InstagramIcon color={COLORS.white} size={18} />
							<p>thermoartdesign</p>
						</a>
					</div>

					<div className='subsection-container'>
						<h4>{t("header.contact")}</h4>

						<div className='contact-row'>
							<MailIcon color={COLORS.white} size={18} />
							<p>office@thermoartdesign.ro</p>
						</div>
						<div className='contact-row'>
							<MailIcon color={COLORS.white} size={18} />
							<p>thermoartdesign@yahoo.com</p>
						</div>
						<a className='contact-row' href='tel:0730622944'>
							<PhoneIcon color={COLORS.white} size={18} />
							<p>0730622944</p>
						</a>
					</div>

					<div className='subsection-container'>
						<h4>SC ROBAND PROD SRL</h4>
						<p>CUI RO 23327843</p>
						<a href='https://maps.app.goo.gl/HhrXZ6cKirTEAZXo7' target='_blank'>
							Showroom - Bd. Pacurari, nr 140, Iasi
						</a>
						<p>{t("interface.office")} - Bd. Roman Musat, Roman, Neamt</p>
						<p>{t("interface.factory")} - Str. Islazului, Roman, Neamt</p>
					</div>
				</div>

				<p id='copyright'>Copyright © {year} Roband Prod SRL</p>
			</div>
		</footer>
	);
}
