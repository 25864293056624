import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyCLhm0-mGLGOIaDcxVucVqcqVrmcxy56a4",
	authDomain: "thermoartdesign-live.firebaseapp.com",
	projectId: "thermoartdesign-live",
	storageBucket: "thermoartdesign-live.appspot.com",
	messagingSenderId: "141901247261",
	appId: "1:141901247261:web:928e2b853d8785f72fa7f4",
	measurementId: "G-JCYM6ZCQT9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;
