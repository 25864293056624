import React from "react";
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from "react-router-dom";
import ProductLayout from "../Layouts.js/ProductLayout.js";
import RootLayout from "../Layouts.js/RootLayout.js";
import Careers from "../pages/Careers.js";
import Contact from "../pages/Contact.js";
import Home from "../pages/Home.js";
import NotFound from "../pages/NotFound.js";
import Partners from "../pages/Partners.js";
import Product from "../pages/Product.js";
import Gallery from "../pages/Gallery.js";

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path='/'>
			<Route element={<RootLayout />}>
				<Route index element={<Home />} />

				<Route path='pvc-alu'>
					<Route path='windows' element={<ProductLayout />}>
						<Route path='rehau' element={<Product />} />
						<Route path='gealan' element={<Product />} />
					</Route>

					<Route path='pvc-doors' element={<ProductLayout />}>
						<Route path='exterior' element={<Product />} />
						<Route path='interior' element={<Product />} />
						<Route path='sliding' element={<Product />} />
					</Route>

					<Route path='glass' element={<ProductLayout />}>
						<Route index element={<Product />} />
					</Route>

					<Route path='rollers' element={<ProductLayout />}>
						<Route index element={<Product />} />
					</Route>

					<Route path='garage-doors' element={<ProductLayout />}>
						<Route index element={<Product />} />
					</Route>

					<Route path='aluminum-joinery' element={<ProductLayout />}>
						<Route index element={<Product />} />
					</Route>
				</Route>

				<Route path='wood'>
					<Route path='furniture' element={<ProductLayout />}>
						<Route path='mdf' element={<Product />} />
						<Route path='pal' element={<Product />} />
					</Route>

					<Route path='doors' element={<ProductLayout />}>
						<Route path='wood' element={<Product />} />
						<Route path='mdf' element={<Product />} />
					</Route>
				</Route>

				<Route path='careers' element={<Careers />} />

				<Route path='partners' element={<Partners />} />

				<Route path='contact' element={<Contact />} />

				<Route path='*' element={<NotFound />} />
			</Route>

			<Route path='gallery' element={<Gallery />} />
		</Route>
	)
);
