import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import analytics from "../firebase";
import { logEvent } from "firebase/analytics";
import { useTranslation } from "react-i18next";

export const useTracking = () => {
	const location = useLocation();
	const { i18n } = useTranslation();

	useEffect(() => {
		logEvent(
			analytics,
			`page_view (lang-${i18n.language}): ${location.pathname}`
		);
	}, [location]);
};
