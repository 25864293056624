import { Images } from "../theme"

export const SUBCATEGORIES = {
    windows: {
      image: Images.windows,
      // placeholder: Images.windows_placeholder,
      products: ['gealan', 'rehau']
    },
    pvc_doors: {
      image: Images.pvc_doors,
      products: ['exterior', 'interior', 'sliding',]
    },
    furniture: {
      image: Images.furnitureHero,
      products: ['mdf', 'pal']
    },
    doors: {
      image: Images.wood_doors,
      products: ['wood', 'mdf']
    },
    aluminum_joinery: {
      image: Images.aluminum_joinery,
      products: null,
    },
    glass: {
      image: Images.glass,
      products: null,
    },
    rollers: {
      image: Images.rollers,
      products: null,
    },
    garage_doors: {
      image: Images.garage_doors,
      products: null,
    },
}
  