import { createGlobalStyle } from 'styled-components';

export const COLORS = {
  red: '#E61525',
  purple: '#A10D58',
  blue: '#512DAF', //or #532EB0,
  yellow: '#FFCE0A',
  white: '#fff',
  black: '#171A1D',
  lightGrey: '#eee',
  mediumGrey: '#ddd',
  darkGrey: '#383e42',
  anthracite: '#596269',
  helpText: '#555',
  selectedGrey: '#F5F5F5',
  hoverGrey: '#F8F8F8',
}

export const FONT_SIZES = {
  tiny: 12,
  small: 14,
  medium: 16,
  large: 18,
  titleSmall: 24,
  titleMedium: 26,
  titleLarge: 28,
  titleExtraLarge: 52,
}

export const GlobalStyles = createGlobalStyle`
  html {
    --black-text: ${COLORS.black};
    --black: ${COLORS.black};
    --red: ${COLORS.red};
    --purple: ${COLORS.purple};
    --yellow: ${COLORS.yellow};
    --blue: ${COLORS.blue};
    --white: ${COLORS.white};
    --light-grey: ${COLORS.lightGrey};
    --medium-grey: ${COLORS.mediumGrey};
    --anthracite: ${COLORS.anthracite};
    --dark-grey: ${COLORS.darkGrey};
    --help-text: ${COLORS.helpText};
    --selected-grey: ${COLORS.selectedGrey};
    --hover-grey: ${COLORS.hoverGrey};
    
    --tiny: ${FONT_SIZES.tiny}px;
    --small: ${FONT_SIZES.small}px;
    --medium: ${FONT_SIZES.medium}px;
    --large: ${FONT_SIZES.large}px;
    --title-small: ${FONT_SIZES.titleSmall}px;
    --title-medium: ${FONT_SIZES.titleMedium}px;
    --title-large: ${FONT_SIZES.titleLarge}px;
    --title-extra-large: ${FONT_SIZES.titleExtraLarge}px;
  }
`

 