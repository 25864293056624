import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ProgressiveImage from 'react-progressive-graceful-image'
import { BsArrowRight } from 'react-icons/bs'
import { COLORS } from '../theme'

export default function ProductHeader({ transKey, galleryKey, subcategory }) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <div className='product-header'>
            <div className='header-container product-header-details-container'>
                <h1 className='text-header'>{t(`products.${transKey}.title`)}</h1>
                <div className='goto-gallery' onClick={() => navigate(`/gallery?category=${transKey}&gallery=${galleryKey}`)}>
                    <a>{t('interface.goToGallery')}</a>
                    <BsArrowRight color={COLORS.white} className='gallery-arrow' />
                </div>
            </div>
            
            <div className='product-header-image-container'>
                <ProgressiveImage src={subcategory.image} placeholder={subcategory.image}>
                    {(src, loading) =>
                        <img src={src} className={`${loading ? 'loading' : 'loaded'}`} alt={t(`products.${transKey}.title`)} />
                    }
                </ProgressiveImage>
            </div>
            
            <div className='overlay-gradient' />
        </div>
    )
}
