import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getSingleCacheData } from '../utils/helpers'
import * as languages from './languages/index.js'

const MAIN_LANG = 'ro'

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources: {
        ...Object.entries(languages).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: {
                    translation: value,
                },
            }),
            {}
        )
    },
    fallbackLng: MAIN_LANG,
    returnObjects: true
})

getSingleCacheData('language', window.location.origin).then(resp => {
    if (resp) {
        i18n.changeLanguage(resp)
    } else {
        const preferredLanguages = navigator.languages
        let browserLanguage = navigator.language.split('-')[0]
        if (preferredLanguages?.some(lang => lang.includes(MAIN_LANG) || lang.includes('en'))) {
            browserLanguage = MAIN_LANG
        }
        
        if (Object.keys(languages).includes(browserLanguage)) {
            i18n.changeLanguage(browserLanguage)
        } else {
            i18n.changeLanguage('en')
        }
    }
})

const en_translation = languages.en
export { i18n as default, en_translation }
