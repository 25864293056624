import React from 'react'
import ContactButton from '../components/ContactButton'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function Partners() {

  const { t } = useTranslation()
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='partners-container'>
      <div className='secondary-page-hero'>
        <h1>{t('header.partners')}</h1>
        <h3>{t('partners.pitchPhrase')}</h3>
        <ContactButton text={t('interface.contactUs')} />
      </div>

      <div className='container'>
        <p id='partners-intro'>{t('partners.intro')}</p>
        <div className='partners' id='extern'>
          <h2>{t('partners.extern')}</h2>
          <p>{t('partners.externContent')}</p>
        </div>
    
        <div className='partners' id='intern'>
          <h2>{t('partners.intern')}</h2>
          <p>{t('partners.internContent')}</p>
        </div>
      </div>
    </div>
  )
}
