export default {
	interface: {
		wantOffer: "Quiero una oferta",
		contactUs: "Contáctanos",
		sendMessage: "Enviar mensaje",
		goToGallery: "Ver galería",
		factory: "Fábrica",
		office: "Oficina",
	},
	tags: {
		title: "ThermoArt Design - Carpintería PVC & Muebles MDF",
		description:
			"¡Construye tu casa con nosotros! Fabricante de carpintería de PVC y aluminio, muebles de MDF y aglomerado, puertas de MDF y más.",
	},
	header: {
		home: "Inicio",
		pvcAluminum: "Carpintería de PVC y Aluminio",
		windows: "Ventanas",
		doors: "Puertas",
		others: "Otros",
		aluminum: "Aluminio",
		interiorDoors: "Puertas interiores",
		exteriorDoors: "Puertas exteriores",
		slidingDoors: "Puertas correderas",
		aluminumJoinery: "Carpintería de aluminio",
		glass: "Vidrio",
		rollers: "Persianas",
		garageDoors: "Puertas de garaje",
		furnitureDoors: "Muebles y Puertas",
		furniture: "Muebles",
		mdfFurniture: "Muebles de MDF",
		palFurniture: "Muebles de PAL",
		woodDoors: "Puertas de madera",
		mdfDoors: "Puertas de MDF",
		careers: "Carreras",
		partners: "Socios",
		contact: "Contacto",
	},
	footer: {
		findOutMore: "¿Quieres saber más sobre nosotros y nuestros productos?",
		partOfTeam: "¿Quieres formar parte de nuestro equipo?",
		ourPartner: "¿Quieres ser nuestro socio?",
		followUs: "Síguenos",
		discoverUs: "Descúbrenos",
	},
	homepage: {
		hero_pvc: "Carpintería de PVC y Aluminio",
		hero_lemn: "Muebles y Puertas Personalizadas",
		about_us: "Tu Deseo, Nuestra Innovación",
		about_us_content:
			"Con más de 20 años de experiencia y un equipo de más de 40 personas, convertimos tu hogar ideal en realidad con nuestros productos personalizados!",
		counter: {
			over: "más de",
			years: "años de experiencia",
			clients: "nuevos clientes",
			orders: "órdenes únicas",
		},
		discover: "Descúbrenos",
		discover_content:
			"Nuestra empresa fue fundada en 2002, como resultado de una década de experiencia y pasión en la industria de la madera. Desde entonces, hemos evolucionado continuamente, mejorando procesos e implementando las últimas tendencias y tecnologías. En 2008, establecimos nuestra sección de PVC y aluminio, lo que llevó a la creación de la marca ThermoArt Design, que combina la maestría y la experiencia en la industria de la madera con la innovación y la tecnología en la carpintería de PVC.\n\nSiendo fabricantes, el cliente trata directamente con nosotros, y sus necesidades son nuestra prioridad en la creación del producto, desde la consulta y el diseño hasta la producción e instalación. Creatividad, innovación y respeto por el cliente, además de nuestra gama de productos, desde carpintería de PVC y aluminio hasta puertas de madera maciza y MDF, hasta muebles de aglomerado, MDF y madera maciza, nos hacen la elección perfecta para el cumplimiento de tu hogar.",
	},
	careers: {
		offer: "¿Qué ofrecemos?",
		expectation: "¿Qué esperamos?",
		pitchPhrase:
			"¿Quieres formar parte de nuestro equipo? ¡Vamos a conocernos mejor!",
		details:
			"¡En ThermoArt Design, siempre estamos ansiosos de trabajar con personas comprometidas y con ganas de mejorar!\nSomos un equipo de más de 40 colegas de diversas edades y en múltiples puestos, pero lo más importante es que todos somos colegas antes que cualquier jerarquía. No prometemos ser una familia, sino un colectivo unido en un ambiente agradable.\n\nEsperamos conocerte sin importar el puesto que busques, ya sea un representante de ventas, un carpintero o un instalador.",
		detailList: [],
		offerList: [
			"Salario motivador",
			"Productos con descuento",
			"Maquinaria de alto rendimiento",
			"Oportunidades de desarrollo",
		],
		expectationList: ["Seriedad", "Compromiso", "Deseo de desarrollo"],
	},
	partners: {
		extern: "Externo",
		intern: "Interno",
		externContent:
			"Con más de 20 años de experiencia y socios en múltiples países como Bélgica, Italia, Reino Unido, Francia y más allá, siempre estamos abiertos a expandir nuestra red de socios para llevar la calidad más allá de las fronteras. Nuestros numerosos pedidos para clientes en países occidentales confirman nuestra seriedad y los estándares europeos a los que nos adherimos.\nPor lo tanto, si estás buscando un crecimiento sostenible con un enfoque en las necesidades del cliente, ¡crezcamos juntos!",
		internContent:
			"Ubicados en el corazón de Moldavia (Neamț), siempre estamos abiertos a colaborar con distribuidores e instaladores de toda la región. Somos un equipo caracterizado por el profesionalismo y la seriedad, lo cual también puede deducirse de nuestra presencia en el mercado durante más de dos décadas.\nSi quieres unirte a una asociación donde la calidad y la satisfacción del cliente son los principios básicos, contáctanos y ¡crezcamos juntos!",
		pitchPhrase:
			"¿Quieres ser nuestro socio? ¡Contáctanos y trabajemos juntos!",
		intro:
			"Creemos en el desarrollo sostenible, y esto no se puede lograr sin un equipo unido. Únete a nosotros en una red nacional e internacional de distribuidores para crecer juntos!",
	},
	contact: {
		name: "Nombre",
		city: "Ciudad",
		phone: "Teléfono",
		message: "Mensaje",
		messagePlaceholder: "¿Cómo podemos ayudarte?",
		nameReq: "¡Nombre es obligatorio!",
		cityReq: "¡Ciudad es obligatoria!",
		phoneReq: "¡Teléfono es obligatorio!",
		invalidPhone: "¡Número no válido!",
		messageTooLong: "¡Mensaje demasiado largo!",
		tooShort: "¡Demasiado corto!",
		tooLong: "¡Demasiado largo!",
	},
	products: {
		pvc_alu: {
			windows: {
				title: "Ventanas PVC",
				rehau: {
					name: "Rehau",
					items: [
						{
							name: "Rehau SYNEGO",
							description:
								"El sistema de ventanas SYNEGO ofrece un ambiente cálido y confortable, contribuyendo significativamente a la conservación del medio ambiente. Con una eficiencia térmica mejorada de hasta un 45%, este sistema es una excelente elección para aquellos que buscan tanto comodidad como sostenibilidad.",
							specifications: [
								{
									name: "cámaras",
									value: "7",
									key: "camere",
								},
								{
									name: "grosor del marco",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "vidrio triple",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "soldadura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "junta",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "aislamiento acústico",
									value: "47 dB",
									key: "izolatie_fonica",
								},
								{
									name: "coeficiente térmico",
									value: "0.94 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "antirrobo",
									value: "clase RC 3",
									key: "antiefractie",
								},
								{
									name: "armadura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				gealan: {
					name: "Gealan",
					items: [
						{
							name: "Gealan S9000",
							description:
								"El sistema S9000 de GEALAN-FUTURA® establece nuevos estándares en confort y eficiencia energética. Combina las últimas innovaciones tecnológicas para ofrecer un aislamiento térmico y acústico inigualable, así como una estructura resistente para ventanas de gran tamaño con un batiente de hasta 2.4 m gracias a la junta STV.\nPara aprovechar al máximo las cualidades de este perfil, el cliente puede elegir la instalación estándar nZEB para casas pasivas, que consiste en la instalación en premarcos o consolas.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "cámaras",
									value: "6",
									key: "camere",
								},
								{
									name: "grosor del marco",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "vidrio triple",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "soldadura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "junta",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "junta",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "aislamiento IKD",
									value: "cámara con espuma",
									key: "profil_spuma",
								},
								{
									name: "coeficiente térmico",
									value: "0.89 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armadura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "antirrobo",
									value: "clase RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan LINEAR",
							description:
								"El nuevo sistema GEALAN LINEAR redefine los estándares en el diseño de ventanas, ofreciendo una estética minimalista perfecta para la arquitectura moderna. Este perfil combina las últimas tecnologías con las nuevas tendencias de diseño, siendo ideal tanto para nuevas construcciones como para renovaciones.",
							image: "gealan_linear",
							specifications: [
								{
									name: "cámaras",
									value: "6",
									key: "camere",
								},
								{
									name: "grosor del marco",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "vidrio triple",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "soldadura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "junta",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "junta",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "coeficiente térmico",
									value: "1 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "refuerzo",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "resistencia a robos",
									value: "clase RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"El sistema S8000 de GEALAN es un equilibrio entre economía y rendimiento. Diseñado para eficiencia y estabilidad, este sistema permite ventanas de gran tamaño sin sacrificar calidad. Una solución inteligente para aquellos que buscan comodidad y durabilidad a un precio ventajoso.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "cámaras",
									value: "5",
									key: "camere",
								},
								{
									name: "grosor del marco",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "vidrio triple",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "soldadura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "coeficiente térmico",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armadura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "antirrobo",
									value: "clase RC 3",
									key: "antiefractie",
								},
							],
						},
					],
				},
			},
			pvc_doors: {
				title: "Puertas de PVC",
				exterior: {
					name: "Exterior",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Las puertas exteriores S9000 de GEALAN-FUTURA® establecen nuevos estándares en términos de confort y eficiencia energética. Este sistema combina la última tecnología para ofrecer un aislamiento térmico y acústico inigualable, además de una estructura robusta incluso para dimensiones impresionantes, gracias a la junta STV.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "Cámaras",
									value: "6",
									key: "camere",
								},
								{
									name: "Grosor del marco",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "Vidrio triple",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "Soldadura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Junta",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "Junta",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "Aislamiento IKD",
									value: "Cámara de espuma",
									key: "profil_spuma",
								},
								{
									name: "Herrajes",
									value: "Hoppe",
									key: "feronerie",
								},
								{
									name: "Armadura",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protección contra robos",
									value: "Clase RC 3",
									key: "antiefractie",
								},
								{
									name: "Cierre",
									value: "multipunto",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armadura",
									value: "prefabricada",
									key: "armatura_dubla",
								},
								{
									name: "Armadura",
									value: "soldado",
									key: "sudura",
								},
								{
									name: "Cerradura",
									value: "eléctrica",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Las puertas exteriores S8000 de GEALAN ofrecen un equilibrio entre economía y rendimiento, y son una elección sabia para aquellos que buscan comodidad y durabilidad a un precio asequible.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "Cámaras",
									value: "5",
									key: "camere",
								},
								{
									name: "Grosor del marco",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Vidrio triple",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "Soldadura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Conductividad térmica",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Armadura",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protección contra robos",
									value: "Clase RC 3",
									key: "antiefractie",
								},
								{
									name: "Cierre",
									value: "multipunto",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armadura",
									value: "prefabricada",
									key: "armatura_dubla",
								},
								{
									name: "Armadura",
									value: "soldado",
									key: "sudura",
								},
								{
									name: "Cerradura",
									value: "eléctrica",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Rehau SYNEGO",
							description:
								"Las puertas exteriores Synego crean un ambiente acogedor y cómodo mientras contribuyen significativamente a la protección del medio ambiente. Con una mejora en la eficiencia térmica de hasta el 45%, este sistema es una excelente elección para aquellos que desean tanto comodidad como durabilidad.",
							specifications: [
								{
									name: "Cámaras",
									value: "7",
									key: "camere",
								},
								{
									name: "Grosor del marco",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "Vidrio triple",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Soldadura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Conductividad térmica",
									value: "1.0 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Protección contra robos",
									value: "Clase RC 3",
									key: "antiefractie",
								},
								{
									name: "Cierre",
									value: "multipunto",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armadura",
									value: "prefabricada",
									key: "armatura_dubla",
								},
								{
									name: "Armadura",
									value: "soldado",
									key: "sudura",
								},
								{
									name: "Cerradura",
									value: "eléctrica",
									key: "broasca_electrica",
								},
							],
						},
					],
				},
				interior: {
					name: "Interiores",
					items: [
						{
							name: "Gealan S8000",
							description:
								"El sistema S8000 de GEALAN es perfecto para puertas interiores, ofreciendo un equilibrio entre ahorro y excelente calidad. Para aquellos que prefieren superficies de vidrio, hay varias opciones disponibles, o puedes crear tu propio diseño para darle un toque único a tu hogar.",
							specifications: [
								{
									name: "Cámaras",
									value: "5",
									key: "camere",
								},
								{
									name: "Grosor del marco",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Soldaduras",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Refuerzo",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				sliding: {
					name: "Correderas",
					items: [
						{
							name: "Rehau Synego Slide",
							description:
								"La puerta corredera Rehau Synego Slide redefine el sentido de espacio y naturaleza en tu hogar. Sus dimensiones impresionantes eliminan las barreras entre el interior y el exterior, al tiempo que ofrecen una excepcional hermeticidad incluso en caso de fuertes lluvias. A pesar de su tamaño y estabilidad, se puede abrir y cerrar fácilmente con un solo dedo gracias a su robusto herraje. Es la solución ideal para una casa que quiere vivir en armonía con su entorno.",
							image: "rehau_synego_slide",
							specifications: [
								{
									name: "Cámaras",
									value: "7",
									key: "camere",
								},
								{
									name: "Grosor del marco",
									value: "110 mm",
									key: "grosime_toc",
								},
								{
									name: "Deslizamiento",
									value: "fácil",
									key: "usa_glisanta",
								},
								{
									name: "Vidrio triple",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Herrajes",
									value: "Roto",
									key: "feronerie",
								},
								{
									name: "Juntas",
									value: "doble",
									key: "garnitura_dubla",
								},
								{
									name: "Soldaduras",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Doble refuerzo",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protección antirrobo",
									value: "Clase RC 2",
									key: "antiefractie",
								},
								{
									name: "Conductividad térmica",
									value: "1.3 W/(m²K)",
									key: "coef_temp_fereastra",
								},
							],
						},
					],
				},
			},
			aluminum_joinery: {
				title: "Carpintería de aluminio",
				items: [
					{
						name: "Carpintería de aluminio para cada sector",
						description:
							"Desde carpintería para edificios industriales hasta puertas correderas interiores, y paredes divisorias para espacios comerciales, nuestra carpintería satisface todas tus necesidades.",
						specifications: [
							{
								name: "Barrera térmica",
								value: "térmica",
								key: "bariera_termica",
							},
						],
					},
				],
			},
			garage_doors: {
				title: "Puertas de garaje",
				items: [
					{
						name: "Puertas seccionales",
						description:
							"Nuestras puertas seccionales combinan durabilidad y minimalismo. Ya sea para edificios residenciales o industriales, con o sin acceso peatonal, nuestra gama cubre todas tus necesidades.",
						specifications: [
							{
								name: "Funcionamiento",
								value: "eléctrico",
								key: "rulou_electric",
							},
							{
								name: "Grosor",
								value: "40 mm",
								key: "grosime_panou",
							},
						],
					},
				],
			},
			rollers: {
				title: "Persianas",
				items: [
					{
						name: "Persianas de aluminio",
						description:
							"Nuestras persianas de aluminio ofrecen mayor comodidad, aislamiento térmico y acústico gracias a las lamas rellenas de espuma, necesarias en un clima cada vez más inestable. Se pueden montar sobre las ventanas o incluso integrarse en el aislamiento para un diseño elegante y minimalista. No tienes que preocuparte por las mosquiteras, ya que las persianas pueden llevar mosquiteras incorporadas.",
						specifications: [
							{
								name: "Funcionamiento",
								value: "eléctrico",
								key: "rulou_electric",
							},
							{
								name: "Pintura",
								value: "electrostática",
								key: "suprafata_vopsita",
							},
							{
								name: "Lamas",
								value: "rellenas de espuma",
								key: "mdf_brut",
							},
						],
					},
				],
			},
			glass: {
				title: "Vidrio",
				items: [
					{
						name: "Vidrio de nuestra gama",
						description:
							"Ofrecemos una amplia gama de tipos de vidrio, dependiendo de tus necesidades y deseos. Aquí hay una lista de las configuraciones más comúnmente utilizadas:",
						specifications: [
							{
								name: "Vidrio",
								value: "templado",
								key: "densitate",
							},
							{
								name: "Vidrio",
								value: "laminado",
								key: "sticla_securizata",
							},
							{
								name: "Float + Low-e (+ Argón)",
								value: "Térmico",
								key: "coef_temp_sticla",
							},
							{
								name: "Tratado",
								value: "térmicamente",
								key: "coef_temp_sticla",
							},
							{
								name: "Vidrio triple",
								value: "54 mm",
								key: "sticla_3",
							},
						],
						list: [
							"Float + Low-e (+ Argón)",
							"4S",
							"3-1-3",
							"Espaciador térmico",
							"Barras internas (para un diseño clásico)",
						],
					},
				],
			},
		},
		wood: {
			furniture: {
				title: "Muebles de MDF & PAL",
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Muebles de MDF lacado",
							description:
								"Nuestros muebles de MDF lacado combinan arte, calidad y funcionalidad en un conjunto armonioso y ofrecen una solución duradera y estética para cada habitación de tu hogar. Gracias a la fabricación personalizada, podemos ayudarte a amueblar cada habitación de tu casa, ya sea que busques muebles para la cocina, la sala de estar, el dormitorio o el baño. Con la tecnología CNC, podemos adaptar cada mueble perfectamente al tema de tu espacio.",
							image: "mobila_mdf",
							specifications: [
								{
									name: "MDF",
									value: "crudo",
									key: "mdf_brut",
								},
								{
									name: "Espesor del panel",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "Ferretería",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Pintura Adler",
									value: "Acrílico / Poliuretano",
									key: "vopsea",
								},
								{
									name: "Encimera de melamina",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "Cornisa",
									value: "decorativa",
									key: "cornisa",
								},
							],
						},
					],
				},
				pal: {
					name: "PAL",
					items: [
						{
							name: "Muebles de PAL",
							description:
								"Nuestros muebles de PAL combinan calidad, gracias al PAL de alta densidad y la ferretería de primera calidad, con minimalismo, gracias a los bordes rectos y las superficies mate o melaminadas, y ofrecen una solución duradera y moderna para cada habitación de tu casa. Gracias a la fabricación personalizada, podemos ayudarte a amueblar cada habitación de tu casa, ya sea que busques muebles para la cocina, la sala de estar, el dormitorio o el baño.",
							specifications: [
								{
									name: "PAL",
									value: "Egger / Kastamonu",
									key: "mdf_brut",
								},
								{
									name: "Espesor del panel",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "Ferretería",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Encimera de melamina",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "Borde ABS",
									value: "2 mm",
									key: "cant_gros",
								},
							],
						},
					],
				},
			},
			doors: {
				title: "Puertas de madera & MDF",
				wood: {
					name: "Madera",
					items: [
						{
							name: "Puertas de madera maciza",
							description:
								"Cada puerta de nuestra gama de madera de tilo es un ejemplo excepcional de artesanía que garantiza durabilidad y un estilo que perdura en el tiempo. Gracias a nuestra experiencia en la elaboración de madera maciza y a nuestra rigurosa selección de materias primas, ofrecemos puertas que resisten no solo el desgaste, sino que complementan perfectamente la estética de cualquier tipo de hogar, ya sea moderno o clásico.",
							image: "usa_lemn",
							specifications: [
								{
									name: "Madera maciza",
									value: "Tilo",
									key: "suprafata_furniruita",
								},
								{
									name: "Cornisa",
									value: "Tilo",
									key: "imbracaminte_perete",
								},
								{
									name: "Revestimiento de tilo",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "Cornisa",
									value: "decorativa",
									key: "cornisa",
								},
								{
									name: "Pintura Adler",
									value: "Acrílico / Poliuretano",
									key: "vopsea",
								},
								{
									name: "Barniz Adler",
									value: "Acrílico / Poliuretano",
									key: "suprafata_vopsita",
								},
								{
									name: "Umbral",
									value: "automático",
									key: "prag_automat",
								},
							],
						},
					],
				},
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Puertas de MDF crudo",
							description:
								"Las puertas de paneles de MDF crudo de nuestro portafolio están diseñadas para satisfacer las necesidades estéticas y funcionales de los clientes que desean la calidad de una puerta de madera maciza a un precio más asequible. La tecnología CNC nos permite adaptar el diseño a cada estilo arquitectónico, ofreciendo una flexibilidad sin precedentes para crear el ambiente perfecto en tu hogar, cualquiera que sea su tema.",
							image: "usa_mdf",
							specifications: [
								{
									name: "MDF",
									value: "crudo",
									key: "mdf_brut",
								},
								{
									name: "Espesor del panel",
									value: "40 mm",
									key: "grosime_panou",
								},
								{
									name: "Revestimiento de tilo",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "Cornisa",
									value: "MDF 18 mm",
									key: "imbracaminte_perete",
								},
								{
									name: "Cornisa",
									value: "decorativa",
									key: "cornisa",
								},
								{
									name: "Pintura Adler",
									value: "Acrílico / Poliuretano",
									key: "vopsea",
								},
								{
									name: "Umbral",
									value: "automático",
									key: "prag_automat",
								},
							],
						},
					],
				},
			},
		},
	},
};
