import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SUBCATEGORIES } from "./constants.js"

export const getSubcategoryDetails = (key) => {
  return SUBCATEGORIES[key.replace('-', '_')]
}

export const roundNearest100 = (num) => {
  return Math.round(num / 100) * 100
}

export const getSubcategoryFromPath = (path) => {
  const category = path.split('/')[2] //path format is: /category/subcategory/product, we need y

  return category
}

export const pathToTranslation = (path, fullPath = true) => { //if false, without product
    let newPath = path.replaceAll('-', '_').substring(1)
    const keys = newPath.split('/')

    if (!fullPath && keys.length > 2) {
      keys.pop()
    }

    return keys.join('.')
}

export const addDataIntoCache = (cacheName, url, response) => {
    // Converting our response into Actual Response form
    const data = new Response(JSON.stringify(response));
  
    if ('caches' in window) {
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
}

export const getSingleCacheData = async (cacheName, url) => {
    try {
      if (typeof caches === 'undefined') return false;
      
      const cacheStorage = await caches.open(cacheName);
      const cachedResponse = await cacheStorage.match(url);
  
      return cachedResponse.json().then((item) => {
        return item
      });
    } catch (e) {
      return
    }
};

export const usePreviousURL = () => {
  const [previousURL, setPreviousURL] = useState(null)
  const location = useLocation()

  useEffect(() => {
    setPreviousURL(location.pathname)
  }, [location])

  return previousURL
};

export const useShouldScrollUp = () => {  
  const previousURL = usePreviousURL() 
  const currentURL = useLocation().pathname
  const [shouldScroll, setShouldScroll] = useState(false)

  useEffect(() => {
    const splittedPrevURL = previousURL?.split('/')
    const splittedCurrURL = currentURL?.split('/')

    if (previousURL && currentURL && (splittedPrevURL[splittedPrevURL.length-2] != splittedCurrURL[splittedCurrURL.length-2] || splittedPrevURL.length < 3 && splittedCurrURL.length < 3)) {
      setShouldScroll(true)
    } else {
      setShouldScroll(false)
    }
  }, [currentURL])

  return shouldScroll
}