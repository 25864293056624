export default {
	interface: {
		wantOffer: "Je veux une offre",
		contactUs: "Contactez-nous",
		sendMessage: "Envoyer le message",
		goToGallery: "Voir la galerie",
		factory: "Usine",
		office: "Bureau",
	},
	tags: {
		title: "ThermoArt Design - Menuiserie PVC & Meubles en MDF",
		description:
			"Construisez votre maison avec nous ! Fabricant de menuiserie en PVC et en aluminium, meubles en MDF et en aggloméré, portes en MDF et plus encore.",
	},
	header: {
		home: "Accueil",
		pvcAluminum: "Menuiserie PVC & Aluminium",
		windows: "Fenêtres",
		doors: "Portes",
		others: "Autres",
		aluminum: "Aluminium",
		interiorDoors: "Portes intérieures",
		exteriorDoors: "Portes extérieures",
		slidingDoors: "Portes coulissantes",
		aluminumJoinery: "Menuiserie en aluminium",
		glass: "Verre",
		rollers: "Volets roulants",
		garageDoors: "Portes de garage",
		furnitureDoors: "Meubles & Portes",
		furniture: "Mobilier",
		mdfFurniture: "Meubles en MDF",
		palFurniture: "Meubles en PAL",
		woodDoors: "Portes en bois",
		mdfDoors: "Portes en MDF",
		careers: "Carrières",
		partners: "Partenaires",
		contact: "Contact",
	},
	footer: {
		findOutMore: "Vous voulez en savoir plus sur nous et nos produits ?",
		partOfTeam: "Vous voulez faire partie de notre équipe ?",
		ourPartner: "Vous voulez être notre partenaire ?",
		followUs: "Suivez-nous",
		discoverUs: "Découvrez-nous",
	},
	homepage: {
		hero_pvc: "Menuiserie PVC & Aluminium",
		hero_lemn: "Mobilier & Portes personnalisés",
		about_us: "Votre souhait, notre innovation",
		about_us_content:
			"Avec plus de 20 ans d’expérience et une équipe de plus de 40 personnes, nous transformons votre maison idéale en réalité grâce à nos produits sur mesure !",
		counter: {
			over: "plus de",
			years: "ans d’expérience",
			clients: "nouveaux clients",
			orders: "commandes uniques",
		},
		discover: "Découvrez-nous",
		discover_content:
			"Notre entreprise a été fondée en 2002, suite à une décennie d'expérience et de passion dans l'industrie du bois. Depuis lors, nous avons continuellement évolué, améliorant les processus et mettant en œuvre les dernières tendances et technologies. En 2008, nous avons établi notre section PVC et aluminium, conduisant à la création de la marque ThermoArt Design, qui combine la maîtrise et l'expérience dans l'industrie du bois avec l'innovation et la technologie dans la menuiserie PVC.\n\nÉtant fabricants, le client traite directement avec nous, et ses besoins sont notre priorité dans la création de produits, de la consultation et de la conception à la production et à l'installation. Créativité, innovation et respect pour le client, ainsi que notre gamme de produits, de la menuiserie en PVC et en aluminium aux portes en bois massif et MDF, au mobilier en aggloméré, MDF et bois massif, font de nous le choix parfait pour l'accomplissement de votre maison.",
	},
	careers: {
		offer: "Que offrons-nous ?",
		expectation: "Qu’attendons-nous ?",
		pitchPhrase:
			"Vous voulez faire partie de notre équipe ? Apprenons à mieux nous connaître !",
		details:
			"Chez ThermoArt Design, nous sommes toujours impatients de travailler avec des personnes engagées et désireuses de s'améliorer!\nNous sommes une équipe de plus de 40 collègues de différents âges et à plusieurs postes, mais le plus important est que nous sommes tous des collègues avant toute hiérarchie. Nous ne promettons pas d'être une famille, mais un collectif uni dans une atmosphère agréable.\n\nNous avons hâte de vous rencontrer, quel que soit le poste que vous recherchez, que vous soyez représentant commercial, charpentier ou installateur.",
		detailList: [],
		offerList: [
			"Salaire motivant",
			"Produits à prix réduit",
			"Équipements performants",
			"Possibilités de développement",
		],
		expectationList: ["Sérieux", "Engagement", "Désir de développement"],
	},
	partners: {
		extern: "Externe",
		intern: "Interne",
		externContent:
			"Avec plus de 20 ans d'expérience et des partenaires dans plusieurs pays tels que la Belgique, l'Italie, le Royaume-Uni, la France et au-delà, nous sommes toujours ouverts à l'élargissement de notre réseau de partenaires pour pousser la qualité au-delà des frontières. Nos nombreuses commandes pour des clients dans les pays occidentaux confirment notre sérieux et les normes européennes auxquelles nous adhérons.\nPar conséquent, si vous recherchez une croissance durable centrée sur les besoins du client, grandissons ensemble!",
		internContent:
			"Situés au cœur de la Moldavie (Neamț), nous sommes toujours ouverts à la collaboration avec les revendeurs et les installateurs de toute la région. Nous sommes une équipe caractérisée par le professionnalisme et le sérieux, ce qui peut également être déduit de notre présence sur le marché depuis plus de deux décennies.\nSi vous souhaitez rejoindre un partenariat où la qualité et la satisfaction du client sont les principes de base, contactez-nous et grandissons ensemble!",
		pitchPhrase:
			"Vous voulez être notre partenaire ? Contactez-nous et travaillons ensemble !",
		intro:
			"Nous croyons en un développement durable, ce qui n’est pas possible sans une équipe unie. Rejoignez-nous dans un réseau national et international de distributeurs pour nous développer ensemble !",
	},
	contact: {
		name: "Nom",
		city: "Ville",
		phone: "Téléphone",
		message: "Message",
		messagePlaceholder: "Comment pouvons-nous vous aider ?",
		nameReq: "Le nom est obligatoire !",
		cityReq: "La ville est obligatoire !",
		phoneReq: "Le téléphone est obligatoire !",
		invalidPhone: "Numéro invalide !",
		messageTooLong: "Message trop long !",
		tooShort: "Trop court !",
		tooLong: "Trop long !",
	},
	products: {
		pvc_alu: {
			windows: {
				title: "Fenêtres en PVC",
				rehau: {
					name: "Rehau",
					items: [
						{
							name: "Rehau SYNEGO",
							description:
								"Le système de fenêtres SYNEGO assure un environnement chaud et confortable tout en contribuant significativement à la préservation de l'environnement. Avec une efficacité thermique améliorée jusqu'à 45%, ce système est un excellent choix pour ceux qui recherchent à la fois le confort et la durabilité.",
							specifications: [
								{
									name: "chambres",
									value: "7",
									key: "camere",
								},
								{
									name: "épaisseur du montant",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "verre triple",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "soudure",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "joint",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "isolation acoustique",
									value: "47 dB",
									key: "izolatie_fonica",
								},
								{
									name: "coefficient thermique",
									value: "0.94 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "anti-effraction",
									value: "classe RC 3",
									key: "antiefractie",
								},
								{
									name: "armature",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				gealan: {
					name: "Gealan",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Le système S9000 de GEALAN-FUTURA® établit de nouvelles normes en matière de confort et d'efficacité énergétique. Il combine les dernières innovations technologiques pour offrir une isolation thermique et acoustique inégalée, ainsi qu'une structure robuste pour des fenêtres de grande taille avec un battant allant jusqu'à 2,4 m grâce au joint STV.\nPour profiter au maximum des qualités de ce profilé, le client peut choisir l'installation standard nZEB pour maisons passives, qui implique une installation dans des pré-cadres ou des consoles.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "chambres",
									value: "6",
									key: "camere",
								},
								{
									name: "épaisseur du montant",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "verre triple",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "soudure",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "joint",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "joint",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "isolation IKD",
									value: "chambre à mousse",
									key: "profil_spuma",
								},
								{
									name: "coefficient thermique",
									value: "0.89 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armature",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "anti-effraction",
									value: "classe RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan LINEAR",
							description:
								"Le nouveau système GEALAN LINEAR redéfinit les standards en matière de conception de fenêtres, offrant une esthétique minimaliste parfaite pour l'architecture moderne. Ce profil combine les dernières technologies avec les nouvelles tendances en matière de design, étant idéal tant pour les nouvelles constructions que pour les rénovations.",
							image: "gealan_linear",
							specifications: [
								{
									name: "chambres",
									value: "6",
									key: "camere",
								},
								{
									name: "épaisseur du cadre",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "verre triplex",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "soudure",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "joint",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "joint",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "coefficient thermique",
									value: "1 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "renforcement",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "résistance à l'effraction",
									value: "classe RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Le système S8000 de GEALAN est un équilibre entre économie et performance. Conçu pour l'efficacité et la stabilité, ce système permet de réaliser des fenêtres de grandes dimensions sans sacrifier la qualité. Une solution intelligente pour ceux qui recherchent le confort et la durabilité à un prix avantageux.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "chambres",
									value: "5",
									key: "camere",
								},
								{
									name: "épaisseur du montant",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "verre triple",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "soudure",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "coefficient thermique",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armature",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "anti-effraction",
									value: "classe RC 3",
									key: "antiefractie",
								},
							],
						},
					],
				},
			},
			pvc_doors: {
				title: "Portes en PVC",
				exterior: {
					name: "Extérieur",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Les portes extérieures S9000 de GEALAN-FUTURA® établissent de nouvelles normes en matière de confort et d'efficacité énergétique. Ce système combine la technologie la plus récente pour offrir une isolation thermique et acoustique inégalée, ainsi qu'une structure robuste même pour des dimensions impressionnantes, grâce au joint STV.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "Chambres",
									value: "6",
									key: "camere",
								},
								{
									name: "Épaisseur du cadre",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "Verre triple",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "Soudure",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Joint",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "Joint",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "Isolation IKD",
									value: "Chambre en mousse",
									key: "profil_spuma",
								},
								{
									name: "Quincaillerie",
									value: "Hoppe",
									key: "feronerie",
								},
								{
									name: "Armature",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protection anti-effraction",
									value: "Classe RC 3",
									key: "antiefractie",
								},
								{
									name: "Verrouillage",
									value: "multipoints",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armature",
									value: "préfabriquée",
									key: "armatura_dubla",
								},
								{
									name: "Armature",
									value: "soudée",
									key: "sudura",
								},
								{
									name: "Serrure",
									value: "électrique",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Les portes extérieures S8000 de GEALAN offrent un équilibre entre économie et performance, et sont un choix judicieux pour ceux qui cherchent du confort et de la durabilité à un prix abordable.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "Chambres",
									value: "5",
									key: "camere",
								},
								{
									name: "Épaisseur du cadre",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Verre triple",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "Soudure",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Coefficient thermique",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Armature",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protection anti-effraction",
									value: "Classe RC 3",
									key: "antiefractie",
								},
								{
									name: "Verrouillage",
									value: "multipoints",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armature",
									value: "préfabriquée",
									key: "armatura_dubla",
								},
								{
									name: "Armature",
									value: "soudée",
									key: "sudura",
								},
								{
									name: "Serrure",
									value: "électrique",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Rehau SYNEGO",
							description:
								"Les portes extérieures Synego créent une atmosphère chaleureuse et confortable tout en contribuant de manière significative à la protection de l'environnement. Avec une efficacité thermique améliorée jusqu'à 45%, ce système est un excellent choix pour ceux qui veulent à la fois du confort et de la durabilité.",
							specifications: [
								{
									name: "Chambres",
									value: "7",
									key: "camere",
								},
								{
									name: "Épaisseur du cadre",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "Verre triple",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Soudure",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Coefficient thermique",
									value: "1.0 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Protection anti-effraction",
									value: "Classe RC 3",
									key: "antiefractie",
								},
								{
									name: "Verrouillage",
									value: "multipoints",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armature",
									value: "préfabriquée",
									key: "armatura_dubla",
								},
								{
									name: "Armature",
									value: "soudée",
									key: "sudura",
								},
								{
									name: "Serrure",
									value: "électrique",
									key: "broasca_electrica",
								},
							],
						},
					],
				},
				interior: {
					name: "Intérieurs",
					items: [
						{
							name: "Gealan S8000",
							description:
								"Le système S8000 de GEALAN est parfait pour les portes intérieures, offrant un équilibre entre économie et qualité. Pour ceux qui préfèrent les surfaces vitrées, une variété d'options est disponible, ou vous pouvez créer votre propre modèle pour donner un style unique à votre maison.",
							specifications: [
								{
									name: "Chambres",
									value: "5",
									key: "camere",
								},
								{
									name: "Épaisseur du cadre",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Soudures",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Renforcement",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				sliding: {
					name: "Coulissantes",
					items: [
						{
							name: "Rehau Synego Slide",
							description:
								"La porte coulissante Rehau Synego Slide redéfinit le sens de l'espace et de la nature chez vous. Ses dimensions impressionnantes éliminent les barrières entre l'intérieur et l'extérieur, tout en offrant une étanchéité exceptionnelle même en cas de pluies abondantes. Malgré sa taille et sa stabilité, elle peut être facilement ouverte et fermée avec un seul doigt grâce à ses ferrures puissantes. C'est la solution idéale pour une maison qui souhaite vivre en harmonie avec son environnement.",
							image: "rehau_synego_slide",
							specifications: [
								{
									name: "Chambres",
									value: "7",
									key: "camere",
								},
								{
									name: "Épaisseur du cadre",
									value: "110 mm",
									key: "grosime_toc",
								},
								{
									name: "Coulissement",
									value: "facile",
									key: "usa_glisanta",
								},
								{
									name: "Triple vitrage",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Ferrures",
									value: "Roto",
									key: "feronerie",
								},
								{
									name: "Joints",
									value: "double",
									key: "garnitura_dubla",
								},
								{
									name: "Soudures",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Double renforcement",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protection anti-intrusion",
									value: "Classe RC 2",
									key: "antiefractie",
								},
								{
									name: "Conductivité thermique",
									value: "1.3 W/(m²K)",
									key: "coef_temp_fereastra",
								},
							],
						},
					],
				},
			},
			aluminum_joinery: {
				title: "Menuiserie en aluminium",
				items: [
					{
						name: "Menuiserie en aluminium pour chaque domaine",
						description:
							"De la menuiserie pour les bâtiments industriels aux portes coulissantes intérieures, en passant par les murs-rideaux pour les espaces commerciaux, notre menuiserie répond à tous vos besoins.",
						specifications: [
							{
								name: "Barrière thermique",
								value: "thermique",
								key: "bariera_termica",
							},
						],
					},
				],
			},
			garage_doors: {
				title: "Portes de garage",
				items: [
					{
						name: "Portes sectionnelles",
						description:
							"Nos portes sectionnelles allient durabilité et minimalisme. Que ce soit pour les bâtiments résidentiels ou industriels, avec ou sans entrée piétonne, notre gamme couvre tous vos besoins.",
						specifications: [
							{
								name: "Fonctionnement",
								value: "électrique",
								key: "rulou_electric",
							},
							{
								name: "Épaisseur",
								value: "40 mm",
								key: "grosime_panou",
							},
						],
					},
				],
			},
			rollers: {
				title: "Volets roulants",
				items: [
					{
						name: "Volets roulants en aluminium",
						description:
							"Nos volets roulants en aluminium offrent un confort accru, une isolation thermique et acoustique grâce à des lames remplies de mousse, nécessaires dans un contexte climatique de plus en plus instable. Ils peuvent être montés au-dessus des fenêtres ou même intégrés à l'isolation pour un design élégant et minimaliste. Vous n'avez pas à vous soucier des moustiquaires, car les volets peuvent être équipés de moustiquaires.",
						specifications: [
							{
								name: "Fonctionnement",
								value: "électrique",
								key: "rulou_electric",
							},
							{
								name: "Peinture",
								value: "électrostatique",
								key: "suprafata_vopsita",
							},
							{
								name: "Lames",
								value: "remplies de mousse",
								key: "mdf_brut",
							},
						],
					},
				],
			},
			glass: {
				title: "Verre",
				items: [
					{
						name: "Verre de notre gamme",
						description:
							"Nous proposons une large gamme de types de verre, en fonction de vos besoins et désirs. Voici une liste des configurations les plus couramment utilisées :",
						specifications: [
							{
								name: "Verre",
								value: "trempé",
								key: "densitate",
							},
							{
								name: "Verre",
								value: "laminé",
								key: "sticla_securizata",
							},
							{
								name: "Traité",
								value: "thermiquement",
								key: "coef_temp_sticla",
							},
							{
								name: "Triple vitrage",
								value: "54 mm",
								key: "sticla_3",
							},
						],
						list: [
							"Float + Low-e (+ Argon)",
							"4S",
							"3-1-3",
							"Espacement thermique",
							"Croisillons intérieurs (pour un design classique)",
						],
					},
				],
			},
		},
		wood: {
			furniture: {
				title: "Meubles en MDF & PAL",
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Meubles en MDF laqué",
							description:
								"Nos meubles en MDF laqué allient art, qualité et fonctionnalité dans un ensemble harmonieux et offrent une solution durable et esthétique pour chaque pièce de votre maison. Grâce à la production sur mesure, nous pouvons vous aider à meubler chaque pièce de votre maison, que vous cherchiez des meubles pour la cuisine, le salon, la chambre ou la salle de bain. Avec la technologie CNC, nous pouvons adapter chaque meuble parfaitement au thème de votre espace.",
							image: "mobila_mdf",
							specifications: [
								{
									name: "MDF",
									value: "brut",
									key: "mdf_brut",
								},
								{
									name: "Épaisseur du panneau",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "Quincaillerie",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Peinture Adler",
									value: "Acrylique / Polyuréthane",
									key: "vopsea",
								},
								{
									name: "Plan de travail en mélamine",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "Encadrement",
									value: "décoratif",
									key: "cornisa",
								},
							],
						},
					],
				},
				pal: {
					name: "PAL",
					items: [
						{
							name: "Meubles en PAL",
							description:
								"Nos meubles en PAL allient qualité, grâce au PAL haute densité et à la quincaillerie de première qualité, avec minimalisme, grâce aux bords droits et aux surfaces mates ou mélaminées, et offrent une solution durable et moderne pour chaque pièce de votre maison. Grâce à la production sur mesure, nous pouvons vous aider à meubler chaque pièce de votre maison, que vous cherchiez des meubles pour la cuisine, le salon, la chambre ou la salle de bain.",
							specifications: [
								{
									name: "PAL",
									value: "Egger / Kastamonu",
									key: "mdf_brut",
								},
								{
									name: "Épaisseur du panneau",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "Quincaillerie",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Plan de travail en mélamine",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "Bordure ABS",
									value: "2 mm",
									key: "cant_gros",
								},
							],
						},
					],
				},
			},
			doors: {
				title: "Portes en bois & MDF",
				wood: {
					name: "Bois",
					items: [
						{
							name: "Portes en bois massif",
							description:
								"Chaque porte de notre gamme en bois de tilleul est un exemple exceptionnel de l'artisanat qui garantit durabilité et un style qui perdure au fil des ans. Grâce à notre expérience dans le traitement du bois massif et à notre sélection rigoureuse des matières premières, nous offrons des portes qui résistent non seulement à l'usure, mais aussi qui complètent parfaitement l'esthétique de tout type de maison, qu'elle soit moderne ou classique.",
							image: "usa_lemn",
							specifications: [
								{
									name: "Bois massif",
									value: "Tilleul",
									key: "suprafata_furniruita",
								},
								{
									name: "Encadrement",
									value: "Tilleul",
									key: "imbracaminte_perete",
								},
								{
									name: "Revêtement en tilleul",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "Encadrement",
									value: "décoratif",
									key: "cornisa",
								},
								{
									name: "Peinture Adler",
									value: "Acrylique / Polyuréthane",
									key: "vopsea",
								},
								{
									name: "Vernis Adler",
									value: "Acrylique / Polyuréthane",
									key: "suprafata_vopsita",
								},
								{
									name: "Seuil",
									value: "automatique",
									key: "prag_automat",
								},
							],
						},
					],
				},
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Portes en MDF brut",
							description:
								"Les portes en panneaux de MDF brut de notre portfolio sont conçues pour répondre aux exigences esthétiques et fonctionnelles des clients qui souhaitent la qualité d'une porte en bois massif à un prix plus abordable. La technologie CNC nous permet d'adapter le design à chaque style architectural, offrant une flexibilité sans précédent pour créer l'atmosphère parfaite dans votre maison, quel que soit son thème.",
							image: "usa_mdf",
							specifications: [
								{
									name: "MDF",
									value: "brut",
									key: "mdf_brut",
								},
								{
									name: "Épaisseur du panneau",
									value: "40 mm",
									key: "grosime_panou",
								},
								{
									name: "Revêtement en tilleul",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "Encadrement",
									value: "MDF 18 mm",
									key: "imbracaminte_perete",
								},
								{
									name: "Encadrement",
									value: "décoratif",
									key: "cornisa",
								},
								{
									name: "Peinture Adler",
									value: "Acrylique / Polyuréthane",
									key: "vopsea",
								},
								{
									name: "Seuil",
									value: "automatique",
									key: "prag_automat",
								},
							],
						},
					],
				},
			},
		},
	},
};
