import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";
import { useShouldScrollUp } from "../utils/helpers.js";
import { useTracking } from "../utils/useTracking.js";

export default function RootLayout() {
	useTracking();
	const location = useLocation();
	const shouldScrollUp = useShouldScrollUp();
	const [checkShouldScroll, setCheckShouldScroll] = useState(false);

	// useEffect(() => {
	//   setCheckShouldScroll(true)
	// }, [location])

	// useEffect(() => {
	//   if (shouldScrollUp) {
	//     window.scrollTo(0, 0)
	//     setCheckShouldScroll(false)
	//   }
	// }, [checkShouldScroll])

	return (
		<div className='root-layout'>
			<Header />

			<main>
				<Outlet />
			</main>

			<Footer />
		</div>
	);
}
