export default {
	interface: {
		wantOffer: "Vreau o oferta",
		contactUs: "Contacteaza-ne",
		sendMessage: "Trimite mesajul",
		goToGallery: "Galerie foto",
		factory: "Fabrica",
		office: "Birou",
	},
	tags: {
		title: "ThermoArt Design - Tamplarie PVC & Mobilier MDF",
		description:
			"Construieste-ti casa cu noi! Producator de tamplarie PVC si aluminiu, mobilier MDF si PAL, usi MDF si nu numai.",
	},
	header: {
		home: "Acasa",
		pvcAluminum: "Tamplarie din PVC & Aluminiu",
		windows: "Ferestre",
		doors: "Usi",
		others: "Altele",
		aluminum: "Aluminiu",
		interiorDoors: "Usi de interior",
		exteriorDoors: "Usi de exterior",
		slidingDoors: "Usi glisante",
		aluminumJoinery: "Tamplarie din aluminiu",
		glass: "Sticla",
		rollers: "Rulouri",
		garageDoors: "Usi de garaj",
		furnitureDoors: "Mobilier & Usi",
		furniture: "Mobilier",
		mdfFurniture: "Mobilier din MDF",
		palFurniture: "Mobilier din PAL",
		woodDoors: "Usi din lemn",
		mdfDoors: "Usi din MDF",
		careers: "Cariere",
		partners: "Dealer",
		contact: "Contact",
	},
	footer: {
		findOutMore: "Vrei sa afli mai multe despre noi si produsele noastre?",
		partOfTeam: "Vrei sa fii parte din echipa noastra?",
		ourPartner: "Vrei sa fii partenerul nostru?",
		followUs: "Urmareste-ne",
		discoverUs: "Descopera-ne",
	},
	homepage: {
		hero_pvc: "Tamplarie de PVC & Aluminiu",
		hero_lemn: "Mobila & Usi Personalizate",
		about_us: "Dorinta ta, inovatia noastra",
		about_us_content:
			"Cu peste 20 de ani de experiență și o echipă de peste 40 de oameni, îți transformăm casa ideală în realitate prin produsele noastre doar la comandă!",
		counter: {
			over: "peste",
			years: "ani de experienta",
			clients: "clienti noi",
			orders: "comenzi unice",
		},
		discover: "Descopera-ne",
		discover_content:
			"Compania noastră a luat naștere în 2002, ca rezultatul unui deceniu de experiență și pasiune în industria lemnului. De atunci am evoluat continuu, îmbunătățind procesele și implementând ultimele tendințe și tehnologii. În 2008 am înființat secția de PVC și aluminiu, ceea ce a dus ulterior la crearea brandului ThermoArt Design, reunind sub o singură entitate măiestria și experiența în industria lemnului cu inovația și tehnologia din domeniul tâmplăriei PVC.\n\nFiind producători, clientul este în relație directă cu noi, iar nevoile sale sunt prioritatea noastră în crearea produsului, de la consultanță și proiectare la producere și montare. Creativitatea, inovația și respectul pentru client, plus gama noastră de produse, de la tâmplărie PVC și aluminiu, la uși din lemn masiv și MDF, până la mobilier din PAL, MDF și lemn masiv, ne fac alegerea perfectă pentru împlinirea casei tale.",
	},
	careers: {
		offer: "Ce oferim?",
		expectation: "Ce asteptam?",
		pitchPhrase:
			"Vrei sa fii parte din echipa noastra? Haide sa ne cunoastem mai bine!",
		details:
			"La ThermoArt Design suntem mereu dornici de a lucra cu oameni implicati si dornici de a fi mai buni!\nSuntem o echipa de peste 40 de colegi de diverse varste si pe multiple pozitii, dar cel mai important lucru este ca toti suntem colegi inainte de orice ierarhie. Nu promitem sa fim o familie, ci un colectiv unit intr-o atmosfera placuta.\n\nTe asteptam sa ne cunoastem indiferent de ce pozitie cauti, reprezentant vanzari, tamplar sau montator.",
		detailList: [],
		offerList: [
			"Salariu motivant",
			"Produse la pret redus",
			"Utilaje performante",
			"Posibilitati de dezvoltare",
		],
		expectationList: ["Seriozitate", "Implicare", "Dorinta de dezvoltare"],
	},
	partners: {
		extern: "Extern",
		intern: "Intern",
		externContent:
			"Cu o experiență de peste 20 de ani și parteneri în multiple țări precum Belgia, Italia, Marea Britanie, Franța și nu numai, suntem mereu deschiși în lărgirea rețelei de parteneri pentru a duce calitatea dincolo de granițe. Numeroasele noastre comenzi pentru clienți din țările occidentale confirmă seriozitatea și standardele europene la care lucrăm.\nPrin urmare, dacă îți dorești o dezvoltare durabilă, cu accent pe nevoile clientului, haide să creștem împreună!",
		internContent:
			"Fiind situați în inima Moldovei (Neamț), suntem deschiși oricând să colaborăm cu dealeri și montatori din întreaga regiune. Suntem o echipă caracterizată de profesionalism și seriozitate, lucru ce poate fi dedus și din existența noastră pe piață de peste două decenii.\nDacă vrei să te alături unui parteneriat unde calitatea și satisfacția clientului sunt principiile de bază, contactează-ne și haide să ne dezvoltăm împreună!",
		pitchPhrase:
			"Vrei sa fim parteneri? Contacteaza-ne si hai sa lucram impreuna!",
		intro:
			"Credem în dezvoltarea durabilă, iar aceasta nu poate fi realizată fără o echipă unită.\nAlătură-te nouă într-o rețea națională și internațională de dealeri pentru a ne dezvolta împreună!",
	},
	contact: {
		name: "Nume",
		city: "Oras",
		phone: "Telefon",
		message: "Mesaj",
		messagePlaceholder: "Cum te putem ajuta?",
		nameReq: "Numele e obligatoriu!",
		cityReq: "Orasul e obligatoriu!",
		phoneReq: "Telefonul e obligatoriu!",
		invalidPhone: "Numar invalid!",
		messageTooLong: "Mesaj prea lung!",
		tooShort: "Prea scurt!",
		tooLong: "Prea lung!",
	},
	products: {
		pvc_alu: {
			windows: {
				title: "Ferestre PVC",
				rehau: {
					name: "Rehau",
					items: [
						{
							name: "Rehau SYNEGO",
							description:
								"Sistemul de ferestre SYNEGO asigură un ambient cald și confortabil, totodată contribuind semnificativ la conservarea mediului înconjurător. Cu o eficiență termică îmbunătățită de până la 45%, acest sistem este o alegere excelentă pentru cei care doresc atât confort cât și sustenabilitate.",
							specifications: [
								{
									name: "camere",
									value: "7",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "sticla triplex",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "garnitura",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "izolatie fonica",
									value: "47 dB",
									key: "izolatie_fonica",
								},
								{
									name: "coeficient termic",
									value: "0.94 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "antiefractie",
									value: "clasa RC 3",
									key: "antiefractie",
								},
								{
									name: "armatura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				gealan: {
					name: "Gealan",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Sistemul S9000 de la GEALAN-FUTURA® setează noi standarde în confort și eficiență energetică. Acesta combină ultimele noutăți în tehnologie pentru a oferi o izolare termică și fonică de neegalat, dar și o structură rezistentă pentru ferestre de dimensiuni impresionabile cu cerceveaua până la 2.4 m datorită garniturii STV.\nPentru a profita la maxim de calitățile acestui profil, poate fi ales montajul la standard nZEB pentru case pasive, care presupune montarea în precadre sau console.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "camere",
									value: "6",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "sticla triplex",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "garnitura",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "garnitura",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "izolare IKD",
									value: "camera cu spuma",
									key: "profil_spuma",
								},
								{
									name: "coeficient termic",
									value: "0.89 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armatura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "antiefractie",
									value: "clasa RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan LINEAR",
							description:
								"Noul sistem GEALAN LINEAR redefinește standardele în proiectarea ferestrelor, oferind o estetică minimalistă, cu unghiuri drepte, perfectă pentru arhitectura modernă. Acest profil îmbină ultimele tehnologii cu noile tendințe în design, oferind un toc și o cercevică înguste, ocupând mai puțin spațiu, dar având performanțe de invidiat datorită celor 6 camere și a celor 3 rânduri de garnituri.",
							image: "gealan_linear",
							specifications: [
								{
									name: "camere",
									value: "6",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "sticla triplex",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "garnitura",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "garnitura",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "coeficient termic",
									value: "1 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armatura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "antiefractie",
									value: "clasa RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Sistemul S8000 de la GEALAN este un echilibru între economie și performanță. Proiectat pentru eficiență și stabilitate, acest sistem face posibile ferestre de mari dimensiuni, fără a sacrifica calitatea. O soluție smart pentru cei care doresc confort și durabilitate la un preț avantajos.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "camere",
									value: "5",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "sticla triplex",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "coeficient termic",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armatura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "antiefractie",
									value: "clasa RC 3",
									key: "antiefractie",
								},
							],
						},
					],
				},
			},
			pvc_doors: {
				title: "Usi PVC",
				exterior: {
					name: "Exterioare",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Ușile de exterior S9000 de la GEALAN-FUTURA® setează noi standarde în confort și eficiență energetică. Acest sistem combină ultimele noutăți în tehnologie pentru a oferi o izolare termică și fonică de neegalat, dar și o structură rezistentă, la dimensiuni impresionabile, datorită garniturii STV.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "camere",
									value: "6",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "sticla triplex",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "garnitura",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "garnitura",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "izolare IKD",
									value: "camera cu spuma",
									key: "profil_spuma",
								},
								{
									name: "feronerie",
									value: "Hoppe",
									key: "feronerie",
								},
								{
									name: "armatura",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "antiefractie",
									value: "clasa RC 3",
									key: "antiefractie",
								},
								{
									name: "inchidere",
									value: "multipunct",
									key: "incuietoare_antiefractie",
								},
								{
									name: "armatura",
									value: "prefezata",
									key: "armatura_dubla",
								},
								{
									name: "armatura",
									value: "sudata",
									key: "sudura",
								},
								{
									name: "yala",
									value: "electrica",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Ușile de exterior S8000 de la GEALAN oferă un echilibru între economie și performanță, fiind o soluție smart pentru cei care doresc confort și durabilitate la un preț avantajos.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "camere",
									value: "5",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "sticla triplex",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "coeficient termic",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armatura",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "antiefractie",
									value: "clasa RC 3",
									key: "antiefractie",
								},
								{
									name: "inchidere",
									value: "multipunct",
									key: "incuietoare_antiefractie",
								},
								{
									name: "armatura",
									value: "prefezata",
									key: "armatura_dubla",
								},
								{
									name: "armatura",
									value: "sudata",
									key: "sudura",
								},
								{
									name: "yala",
									value: "electrica",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Rehau SYNEGO",
							description:
								"Ușile de exterior Synego asigură un ambient cald și confortabil, totodată contribuind semnificativ la conservarea mediului înconjurător. Cu o eficiență termică îmbunătățită de până la 45%, acest sistem este o alegere excelentă pentru cei care doresc atât confort cât și sustenabilitate.",
							specifications: [
								{
									name: "camere",
									value: "7",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "sticla triplex",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "garnitura",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "izolatie fonica",
									value: "47 dB",
									key: "izolatie_fonica",
								},
								{
									name: "coeficient termic",
									value: "0.94 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "antiefractie",
									value: "clasa RC 3",
									key: "antiefractie",
								},
								{
									name: "armatura",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "inchidere",
									value: "multipunct",
									key: "incuietoare_antiefractie",
								},
								{
									name: "armatura",
									value: "prefezata",
									key: "armatura_dubla",
								},
								{
									name: "armatura",
									value: "sudata",
									key: "sudura",
								},
								{
									name: "yala",
									value: "electrica",
									key: "broasca_electrica",
								},
							],
						},
					],
				},
				interior: {
					name: "Interioare",
					items: [
						{
							name: "Gealan S8000",
							description:
								"Sistemul S8000 de la GEALAN este perfect pentru ușile de interior, oferind un echilibru între economie și calitate. Cei care doresc suprafețe vitrate, pot alege dintr-o gamă variată sau își pot personaliza propriul model, aducând un stil propriu casei lor.",
							specifications: [
								{
									name: "camere",
									value: "5",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "armatura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				sliding: {
					name: "Glisante",
					items: [
						{
							name: "Rehau Synego Slide",
							description:
								"Ușa glisantă Rehau Synego Slide redefinește noțiunea de spațiu și natură în confortul propriului cămin. Dimensiunile sale impresionabile elimină barierele dintre interior și exterior, în timp ce etanșeitatea excepțională asigură protecție chiar și în fața ploilor torențiale. În ciuda dimensiunilor și a rezistenței sale, datorită feroneriei performante, aceasta poate fi glisată cu un singur deget atât din interior, cât și din exterior. Este soluția ideală pentru o locuință care aspiră la armonie cu mediul înconjurător.",
							image: "rehau_synego_slide",
							specifications: [
								{
									name: "camere",
									value: "7",
									key: "camere",
								},
								{
									name: "grosime toc",
									value: "110 mm",
									key: "grosime_toc",
								},
								{
									name: "glisare",
									value: "usoara",
									key: "usa_glisanta",
								},
								{
									name: "sticla triplex",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "feronerie",
									value: "Roto",
									key: "feronerie",
								},
								{
									name: "garnitura",
									value: "dubla",
									key: "garnitura_dubla",
								},
								{
									name: "sudura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "armatura dubla",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "antiefractie",
									value: "clasa RC 2",
									key: "antiefractie",
								},
								{
									name: "coeficient termic",
									value: "1.3 W/(m²K)",
									key: "coef_temp_fereastra",
								},
							],
						},
					],
				},
			},
			aluminum_joinery: {
				title: "Tamplarie din Aluminiu",
				items: [
					{
						name: "Tâmplărie din aluminiu pentru orice domeniu",
						description:
							"De la tâmplărie pentru clădiri industriale, uși culisante de interior, la pereți cortină pentru spații comerciale, tâmplăria noastră răspunde tuturor nevoilor tale.",
						specifications: [
							{
								name: "bariera",
								value: "termica",
								key: "bariera_termica",
							},
						],
					},
				],
			},
			garage_doors: {
				title: "Usi de Garaj",
				items: [
					{
						name: "Uși de garaj secționate",
						description:
							"Ușile noastre de garaj secționate îmbină durabilitatea și minimalismul. De la uși rezidențiale, la cele industriale, cu sau fără acces pietonal, gama noastră îți acoperă toate nevoile.",
						specifications: [
							{
								name: "actionare",
								value: "electrica",
								key: "rulou_electric",
							},
							{
								name: "grosime",
								value: "40 mm",
								key: "grosime_panou",
							},
						],
					},
				],
			},
			rollers: {
				title: "Rulouri",
				items: [
					{
						name: "Rulouri din aluminiu",
						description:
							"Rulourile noastre din aluminiu oferă un confort sporit, izolând termic și fonic datorită lamelelor umplute cu spumă, fiind necesare într-un context climatic tot mai instabil. Acestea pot fi suprapuse, adică montate deasupra ferestrelor, chiar încastrate în izolație, pentru un design elegant și minimalist. De asemenea, nu trebuie să îți faci griji pentru plasele de insecte, deoarece rulourile pot fi accesorizate cu plase de insecte tip rulou.",
						specifications: [
							{
								name: "actionare",
								value: "electrica",
								key: "rulou_electric",
							},
							{
								name: "vopsite",
								value: "electrostatic",
								key: "suprafata_vopsita",
							},
							{
								name: "lamele",
								value: "cu spuma",
								key: "mdf_brut",
							},
						],
					},
				],
			},
			glass: {
				title: "Sticla",
				items: [
					{
						name: "Sticla din gama noastra",
						description:
							"Oferim o gamă largă de tipuri de sticlă, în funcție de nevoile și dorințele tale. Mai jos prezentăm o listă cu cele mai folosite configurații:",
						specifications: [
							{
								name: "sticla",
								value: "securizata",
								key: "densitate",
							},
							{
								name: "sticla",
								value: "laminata",
								key: "sticla_securizata",
							},
							{
								name: "tratata",
								value: "termic",
								key: "coef_temp_sticla",
							},
							{
								name: "sticla triplex",
								value: "54 mm",
								key: "sticla_3",
							},
						],
						list: [
							"Float + Low-e (+ argon)",
							"4S",
							"3-1-3",
							"Baghetă caldă",
							"Șproșuri interioare (pentru un design clasic)",
						],
					},
				],
			},
		},
		wood: {
			furniture: {
				title: "Mobilier MDF & PAL",
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Mobilier din MDF vopsit",
							description:
								"Mobila noastră din MDF vopsit îmbină arta, calitatea și funcționalitatea într-un ansamblu armonios, oferind o soluție durabilă și estetică pentru fiecare cameră din locuința ta. Datorită producției doar la comandă, te putem ajuta în echiparea fiecărei camere din casa ta, indiferent dacă ești în căutarea unui mobilier pentru bucătărie, living, dormitor sau baie. Grație tehnologiei CNC, putem personaliza fiecare piesă de mobilier pentru a se potrivi perfect cu tematica spațiului tău.",
							image: "mobila_mdf",
							specifications: [
								{
									name: "MDF",
									value: "brut",
									key: "mdf_brut",
								},
								{
									name: "grosime panou",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "feronerie",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "vopsea Adler",
									value: "acrilică / poliuretanică",
									key: "vopsea",
								},
								{
									name: "blat melaminat",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "ancadrament",
									value: "decorativ",
									key: "cornisa",
								},
							],
						},
					],
				},
				pal: {
					name: "PAL",
					items: [
						{
							name: "Mobilier din PAL",
							description:
								"Mobila noastră din PAL aduce împreună calitatea, datorată PAL-ului de înaltă densitate și a feroneriei de top, cu minimalismul, datorat muchiilor drepte și suprafețelor mate sau melaminate, oferind o soluție durabilă și modernă pentru fiecare cameră din locuința ta. Datorită producției doar la comandă, te putem ajuta în echiparea fiecărei camere din casa ta, indiferent dacă ești în căutarea unui mobilier pentru bucătărie, living, dormitor sau baie.",
							specifications: [
								{
									name: "PAL",
									value: "Egger / Kastamonu",
									key: "mdf_brut",
								},
								{
									name: "grosime panou",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "feronerie",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "blat melaminat",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "cant ABS",
									value: "2 mm",
									key: "cant_gros",
								},
							],
						},
					],
				},
			},
			doors: {
				title: "Usi din lemn & MDF",
				wood: {
					name: "Lemn",
					items: [
						{
							name: "Uși din lemn masiv",
							description:
								"Fiecare ușă din gama noastră de lemn de tei este un exemplu de artizanat de înaltă clasă, garantând o durabilitate și un stil care se păstrează de-a lungul anilor. Datorită experienței noastre în prelucrarea lemnului masiv și a selecției riguroase a materiei prime, oferim uși care nu doar că rezistă la uzură, dar și completează perfect estetica oricărui tip de locuință, fie ea modernă sau clasică.",
							image: "usa_lemn",
							specifications: [
								{
									name: "Lemn masiv",
									value: "tei",
									key: "suprafata_furniruita",
								},
								{
									name: "pervaz",
									value: "tei",
									key: "imbracaminte_perete",
								},
								{
									name: "toc de tei",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "ancadrament",
									value: "decorativ",
									key: "cornisa",
								},
								{
									name: "vopsea Adler",
									value: "acrilică / poliuretanică",
									key: "vopsea",
								},
								{
									name: "lac Adler",
									value: "acrilic / poliuretanic",
									key: "suprafata_vopsita",
								},
								{
									name: "prag",
									value: "automat",
									key: "prag_automat",
								},
							],
						},
					],
				},
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Uși din MDF brut",
							description:
								"Ușile din panou de MDF brut din portofoliul nostru sunt concepute pentru a satisface cerințele estetice și funcționale ale clienților care își doresc calitatea unei uși din lemn masiv la un preț mai accesibil. Tehnologia CNC ne permite să adaptăm designul la orice stil arhitectural, oferind astfel o flexibilitate inegalabilă pentru a crea ambianța perfectă în casa ta, indiferent de tematica acesteia.",
							image: "usa_mdf",
							specifications: [
								{
									name: "MDF",
									value: "brut",
									key: "mdf_brut",
								},
								{
									name: "grosime panou",
									value: "40 mm",
									key: "grosime_panou",
								},
								{
									name: "toc de tei",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "pervaz",
									value: "MDF 18 mm",
									key: "imbracaminte_perete",
								},
								{
									name: "ancadrament",
									value: "decorativ",
									key: "cornisa",
								},
								{
									name: "vopsea Adler",
									value: "acrilică / poliuretanică",
									key: "vopsea",
								},
								{
									name: "prag",
									value: "automat",
									key: "prag_automat",
								},
							],
						},
					],
				},
			},
		},
	},
};
