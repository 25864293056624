import React from 'react'

export default function ContactButton({ text, ...props }) {

  const handleClick = () => {
    if (props.type != 'submit') {
      window.open('/contact', '_blank')
    }
  }

  return (
    <button className='cta contact-btn' {...props} onClick={handleClick}>
      {text}
    </button>
  )
}
