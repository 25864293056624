export default {
	interface: {
		wantOffer: "Angebot anfordern",
		contactUs: "Kontaktiere uns",
		sendMessage: "Nachricht senden",
		goToGallery: "Galerie ansehen",
		factory: "Fabrik",
		office: "Büro",
	},
	tags: {
		title: "ThermoArt Design - PVC-Tischlerei & MDF-Möbel",
		description:
			"Baue dein Zuhause mit uns! Hersteller von PVC- und Aluminium-Tischlerei, MDF- und Spanplattenmöbel, MDF-Türen und mehr.",
	},
	header: {
		home: "Startseite",
		pvcAluminum: "PVC & Aluminium Tischlerei",
		windows: "Fenster",
		doors: "Türen",
		others: "Andere",
		aluminum: "Aluminium",
		interiorDoors: "Innentüren",
		exteriorDoors: "Außentüren",
		slidingDoors: "Schiebetüren",
		aluminumJoinery: "Aluminium-Tischlerei",
		glass: "Glas",
		rollers: "Rollos",
		garageDoors: "Garagentore",
		furnitureDoors: "Möbel & Türen",
		furniture: "Möbel",
		mdfFurniture: "MDF Möbel",
		palFurniture: "PAL Möbel",
		woodDoors: "Holztüren",
		mdfDoors: "MDF Türen",
		careers: "Karriere",
		partners: "Händler",
		contact: "Kontakt",
	},
	footer: {
		findOutMore: "Möchten Sie mehr über uns und unsere Produkte erfahren?",
		partOfTeam: "Möchten Sie Teil unseres Teams sein?",
		ourPartner: "Möchten Sie unser Partner sein?",
		followUs: "Folge uns",
		discoverUs: "Entdecke uns",
	},
	homepage: {
		hero_pvc: "PVC & Aluminium Tischlerei",
		hero_lemn: "Individuelle Möbel & Türen",
		about_us: "Ihr Wunsch, unsere Innovation",
		about_us_content:
			"Mit über 20 Jahren Erfahrung und einem Team von über 40 Personen verwandeln wir Ihr Traumhaus in die Realität durch unsere maßgeschneiderten Produkte!",
		counter: {
			over: "über",
			years: "Jahre Erfahrung",
			clients: "neue Kunden",
			orders: "einzigartige Bestellungen",
		},
		discover: "Entdecke uns",
		discover_content:
			"Unser Unternehmen wurde im Jahr 2002 gegründet, als Ergebnis eines Jahrzehnts an Erfahrung und Leidenschaft in der Holzindustrie. Seitdem haben wir uns kontinuierlich weiterentwickelt, Prozesse verbessert und die neuesten Trends und Technologien implementiert. Im Jahr 2008 haben wir unseren PVC- und Aluminium-Bereich etabliert, was zur Gründung der Marke ThermoArt Design führte, die Fachkenntnis und Erfahrung in der Holzindustrie mit Innovation und Technologie in der PVC-Fensterbau vereint.\n\nAls Hersteller tritt der Kunde direkt mit uns in Kontakt, und seine Bedürfnisse sind unsere Priorität bei der Produktentwicklung, von der Beratung und dem Design bis zur Produktion und Installation. Kreativität, Innovation und Respekt für den Kunden, sowie unsere Produktpalette, von PVC- und Aluminium-Fensterbau bis zu Massivholz- und MDF-Türen, zu Spanplatten, MDF und Massivholzmöbeln, machen uns zur perfekten Wahl für die Erfüllung Ihres Zuhauses.",
	},
	careers: {
		offer: "Was bieten wir?",
		expectation: "Was erwarten wir?",
		pitchPhrase:
			"Möchten Sie Teil unseres Teams sein? Lassen Sie uns einander besser kennenlernen!",
		details:
			"Bei ThermoArt Design sind wir immer gespannt darauf, mit engagierten und aufstrebenden Menschen zu arbeiten!\nWir sind ein Team von über 40 Kollegen unterschiedlichen Alters und in verschiedenen Positionen, aber das Wichtigste ist, dass wir alle Kollegen vor jeglicher Hierarchie sind. Wir versprechen nicht, eine Familie zu sein, sondern ein vereintes Kollektiv in einer angenehmen Atmosphäre.\n\nWir freuen uns darauf, Sie kennenzulernen, unabhängig von der Position, die Sie suchen, sei es ein Verkaufsvertreter, ein Zimmermann oder ein Installateur.",
		detailList: [],
		offerList: [
			"Motivierendes Gehalt",
			"Produkte zum reduzierten Preis",
			"Hochleistungsmaschinen",
			"Entwicklungsmöglichkeiten",
		],
		expectationList: [
			"Zuverlässigkeit",
			"Engagement",
			"Wunsch nach Weiterentwicklung",
		],
	},
	partners: {
		extern: "Extern",
		intern: "Intern",
		externContent:
			"Mit über 20 Jahren Erfahrung und Partnern in mehreren Ländern wie Belgien, Italien, dem Vereinigten Königreich, Frankreich und darüber hinaus sind wir immer offen für die Erweiterung unseres Partnernetzwerks, um Qualität über die Grenzen hinaus zu bringen. Unsere zahlreichen Aufträge für Kunden in westlichen Ländern bestätigen unsere Seriosität und die europäischen Standards, an die wir uns halten.\nDaher, wenn Sie nach nachhaltigem Wachstum mit Schwerpunkt auf Kundenbedürfnissen suchen, lasst uns gemeinsam wachsen!",
		internContent:
			"Im Herzen Moldaus (Neamț) gelegen, sind wir immer offen für die Zusammenarbeit mit Händlern und Installateuren aus der gesamten Region. Wir sind ein Team, das sich durch Professionalität und Ernsthaftigkeit auszeichnet, was auch aus unserer mehr als zwanzigjährigen Präsenz am Markt abgeleitet werden kann.\nWenn Sie sich einer Partnerschaft anschließen möchten, bei der Qualität und Kundenzufriedenheit die Grundprinzipien sind, kontaktieren Sie uns und lasst uns gemeinsam wachsen!",
		pitchPhrase:
			"Möchten Sie unser Partner sein? Kontaktieren Sie uns und arbeiten wir zusammen!",
		intro:
			"Wir glauben an nachhaltige Entwicklung, und das ist ohne ein vereintes Team nicht möglich. Werden Sie Teil unseres nationalen und internationalen Händlernetzwerks, um gemeinsam zu wachsen!",
	},
	contact: {
		name: "Name",
		city: "Stadt",
		phone: "Telefon",
		message: "Nachricht",
		messagePlaceholder: "Wie können wir Ihnen helfen?",
		nameReq: "Name ist erforderlich!",
		cityReq: "Stadt ist erforderlich!",
		phoneReq: "Telefon ist erforderlich!",
		invalidPhone: "Ungültige Nummer!",
		messageTooLong: "Nachricht zu lang!",
		tooShort: "Zu kurz!",
		tooLong: "Zu lang!",
	},
	products: {
		pvc_alu: {
			windows: {
				title: "PVC-Fenster",
				rehau: {
					name: "Rehau",
					items: [
						{
							name: "Rehau SYNEGO",
							description:
								"Das Fenstersystem SYNEGO sorgt für ein warmes und komfortables Umfeld und leistet gleichzeitig einen erheblichen Beitrag zum Umweltschutz. Mit einer um bis zu 45% verbesserten Wärmeleistung ist dieses System eine hervorragende Wahl für diejenigen, die sowohl Komfort als auch Nachhaltigkeit wünschen.",
							specifications: [
								{
									name: "Kammern",
									value: "7",
									key: "camere",
								},
								{
									name: "Rahmendicke",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "Triplex-Glas",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Dichtung",
									value: "dreifach",
									key: "garnitura_tripla",
								},
								{
									name: "Schallschutz",
									value: "47 dB",
									key: "izolatie_fonica",
								},
								{
									name: "Wärmeleistungskoeffizient",
									value: "0.94 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Einbruchschutz",
									value: "Klasse RC 3",
									key: "antiefractie",
								},
								{
									name: "Verstärkung",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				gealan: {
					name: "Gealan",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Das S9000-System von GEALAN-FUTURA® setzt neue Maßstäbe in Komfort und Energieeffizienz. Es kombiniert neueste technologische Entwicklungen für unübertroffenen Wärme- und Schallschutz sowie eine robuste Struktur für beeindruckend große Fenster mit einem Flügel von bis zu 2,4 m dank der STV-Dichtung.\nUm die Qualitäten dieses Profils optimal zu nutzen, kann der Kunde den nZEB-Standardeinbau für Passivhäuser wählen, der den Einbau in Vorrahmen oder Konsolen beinhaltet.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "Kammern",
									value: "6",
									key: "camere",
								},
								{
									name: "Rahmendicke",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "Triplex-Glas",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Dichtung",
									value: "dreifach",
									key: "garnitura_tripla",
								},
								{
									name: "Dichtung",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "IKD-Isolierung",
									value: "Schaumkammer",
									key: "profil_spuma",
								},
								{
									name: "Wärmeleistungskoeffizient",
									value: "0.89 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Verstärkung",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "Einbruchschutz",
									value: "Klasse RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan LINEAR",
							description:
								"Das neue GEALAN LINEAR System definiert die Standards im Fensterdesign neu und bietet eine minimalistische Ästhetik, die perfekt für moderne Architektur geeignet ist. Dieses Profil kombiniert die neuesten Technologien mit neuen Designtrends und ist sowohl für Neubauten als auch für Renovierungen ideal.",
							image: "gealan_linear",
							specifications: [
								{
									name: "Kammern",
									value: "6",
									key: "camere",
								},
								{
									name: "Rahmenstärke",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Dreifachverglasung",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Dichtung",
									value: "dreifach",
									key: "garnitura_tripla",
								},
								{
									name: "Dichtung",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "Wärmekoeffizient",
									value: "1 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Verstärkung",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "Einbruchschutz",
									value: "Klasse RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Das S8000-System von GEALAN ist ein Gleichgewicht zwischen Wirtschaftlichkeit und Leistung. Es ist für Effizienz und Stabilität konzipiert und ermöglicht große Fenster, ohne an Qualität zu sparen. Eine kluge Lösung für diejenigen, die Komfort und Haltbarkeit zu einem günstigen Preis wünschen.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "Kammern",
									value: "5",
									key: "camere",
								},
								{
									name: "Rahmendicke",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Triplex-Glas",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Wärmeleistungskoeffizient",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Verstärkung",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "Einbruchschutz",
									value: "Klasse RC 3",
									key: "antiefractie",
								},
							],
						},
					],
				},
			},
			pvc_doors: {
				title: "PVC Türen",
				exterior: {
					name: "Außen",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Die Außentüren S9000 von GEALAN-FUTURA® setzen neue Maßstäbe in Sachen Komfort und Energieeffizienz. Dieses System kombiniert neueste Technologie, um unübertroffene thermische und akustische Isolierung sowie eine robuste Struktur bei beeindruckenden Größen zu bieten, dank der STV-Dichtung.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "Kammern",
									value: "6",
									key: "camere",
								},
								{
									name: "Rahmenstärke",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "Triplex Glas",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Dichtung",
									value: "dreifach",
									key: "garnitura_tripla",
								},
								{
									name: "Dichtung",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "IKD Isolierung",
									value: "Schaumkammer",
									key: "profil_spuma",
								},
								{
									name: "Beschläge",
									value: "Hoppe",
									key: "feronerie",
								},
								{
									name: "Bewehrung",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Einbruchschutz",
									value: "Klasse RC 3",
									key: "antiefractie",
								},
								{
									name: "Verschluss",
									value: "mehrpunktig",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Bewehrung",
									value: "vorgefertigt",
									key: "armatura_dubla",
								},
								{
									name: "Bewehrung",
									value: "geschweißt",
									key: "sudura",
								},
								{
									name: "Schloss",
									value: "elektrisch",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Die Außentüren S8000 von GEALAN bieten eine ausgeglichene Mischung aus Wirtschaftlichkeit und Leistung, und sind eine kluge Wahl für diejenigen, die Komfort und Langlebigkeit zu einem günstigen Preis suchen.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "Kammern",
									value: "5",
									key: "camere",
								},
								{
									name: "Rahmenstärke",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Triplex Glas",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Wärmedurchgangskoeffizient",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Bewehrung",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Einbruchschutz",
									value: "Klasse RC 3",
									key: "antiefractie",
								},
								{
									name: "Verschluss",
									value: "mehrpunktig",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Bewehrung",
									value: "vorgefertigt",
									key: "armatura_dubla",
								},
								{
									name: "Bewehrung",
									value: "geschweißt",
									key: "sudura",
								},
								{
									name: "Schloss",
									value: "elektrisch",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Rehau SYNEGO",
							description:
								"Die Außentüren Synego sorgen für eine warme und komfortable Atmosphäre, während sie auch einen wesentlichen Beitrag zum Umweltschutz leisten. Mit einer verbesserten thermischen Effizienz von bis zu 45% ist dieses System eine ausgezeichnete Wahl für diejenigen, die sowohl Komfort als auch Nachhaltigkeit wünschen.",
							specifications: [
								{
									name: "Kammern",
									value: "7",
									key: "camere",
								},
								{
									name: "Rahmenstärke",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "Triplex Glas",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Wärmedurchgangskoeffizient",
									value: "1.0 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Einbruchschutz",
									value: "Klasse RC 3",
									key: "antiefractie",
								},
								{
									name: "Verschluss",
									value: "mehrpunktig",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Bewehrung",
									value: "vorgefertigt",
									key: "armatura_dubla",
								},
								{
									name: "Bewehrung",
									value: "geschweißt",
									key: "sudura",
								},
								{
									name: "Schloss",
									value: "elektrisch",
									key: "broasca_electrica",
								},
							],
						},
					],
				},
				interior: {
					name: "Innenräume",
					items: [
						{
							name: "Gealan S8000",
							description:
								"Das S8000-System von GEALAN ist perfekt für Innentüren geeignet und bietet ein Gleichgewicht zwischen Wirtschaftlichkeit und Qualität. Wer verglaste Oberflächen wünscht, kann aus einer Vielzahl von Optionen wählen oder sein eigenes Modell gestalten, um seinem Zuhause einen individuellen Stil zu verleihen.",
							specifications: [
								{
									name: "Kammern",
									value: "5",
									key: "camere",
								},
								{
									name: "Rahmendicke",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Verstärkung",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				sliding: {
					name: "Schiebetüren",
					items: [
						{
							name: "Rehau Synego Slide",
							description:
								"Die Schiebetür Rehau Synego Slide definiert den Begriff von Raum und Natur im eigenen Zuhause neu. Ihre beeindruckenden Abmessungen beseitigen Barrieren zwischen Innen- und Außenraum, während die außergewöhnliche Dichtigkeit selbst bei Starkregen Schutz bietet. Trotz ihrer Größe und Stabilität kann sie dank der leistungsstarken Beschläge mit nur einem Finger von innen und außen geschoben werden. Sie ist die ideale Lösung für ein Zuhause, das in Harmonie mit seiner Umgebung leben möchte.",
							image: "rehau_synego_slide",
							specifications: [
								{
									name: "Kammern",
									value: "7",
									key: "camere",
								},
								{
									name: "Rahmendicke",
									value: "110 mm",
									key: "grosime_toc",
								},
								{
									name: "Schieben",
									value: "leicht",
									key: "usa_glisanta",
								},
								{
									name: "Dreifachglas",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Beschläge",
									value: "Roto",
									key: "feronerie",
								},
								{
									name: "Dichtung",
									value: "doppelt",
									key: "garnitura_dubla",
								},
								{
									name: "Schweißnaht",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Doppelte Verstärkung",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Einbruchschutz",
									value: "Klasse RC 2",
									key: "antiefractie",
								},
								{
									name: "Wärmeleitfaktor",
									value: "1.3 W/(m²K)",
									key: "coef_temp_fereastra",
								},
							],
						},
					],
				},
			},
			aluminum_joinery: {
				title: "Aluminium-Tischlerei",
				items: [
					{
						name: "Aluminium-Tischlerei für jeden Bereich",
						description:
							"Von Tischlereiarbeiten für Industriegebäude und Innenschiebetüren bis hin zu Vorhangwänden für Geschäftsräume erfüllt unsere Tischlerei all Ihre Bedürfnisse.",
						specifications: [
							{
								name: "Wärmebarriere",
								value: "thermisch",
								key: "bariera_termica",
							},
						],
					},
				],
			},
			garage_doors: {
				title: "Garagentore",
				items: [
					{
						name: "Sektionaltore",
						description:
							"Unsere Sektionaltore kombinieren Langlebigkeit und Minimalismus. Ob für Wohn- oder Industriegebäude, mit oder ohne Fußgängereingang – unsere Auswahl deckt all Ihre Bedürfnisse ab.",
						specifications: [
							{
								name: "Betrieb",
								value: "elektrisch",
								key: "rulou_electric",
							},
							{
								name: "Dicke",
								value: "40 mm",
								key: "grosime_panou",
							},
						],
					},
				],
			},
			rollers: {
				title: "Rollläden",
				items: [
					{
						name: "Aluminium-Rollläden",
						description:
							"Unsere Aluminium-Rollläden bieten erhöhten Komfort, thermische und akustische Isolierung dank mit Schaum gefüllten Lamellen, die in einem zunehmend instabilen Klimakontext notwendig sind. Sie können oberhalb der Fenster montiert oder sogar in die Isolierung integriert werden, für ein elegantes und minimalistisches Design. Sie müssen sich auch keine Sorgen um Insektengitter machen, denn die Rollläden können mit Insektenschutzgittern ausgestattet werden.",
						specifications: [
							{
								name: "Betrieb",
								value: "elektrisch",
								key: "rulou_electric",
							},
							{
								name: "Lackiert",
								value: "elektrostatisch",
								key: "suprafata_vopsita",
							},
							{
								name: "Lamellen",
								value: "mit Schaum",
								key: "mdf_brut",
							},
						],
					},
				],
			},
			glass: {
				title: "Glas",
				items: [
					{
						name: "Glas aus unserem Sortiment",
						description:
							"Wir bieten eine breite Palette von Glasarten an, abhängig von Ihren Bedürfnissen und Wünschen. Unten finden Sie eine Liste der am häufigsten verwendeten Konfigurationen:",
						specifications: [
							{
								name: "Glas",
								value: "gehärtet",
								key: "densitate",
							},
							{
								name: "Glas",
								value: "laminat",
								key: "sticla_securizata",
							},
							{
								name: "Behandelt",
								value: "thermisch",
								key: "coef_temp_sticla",
							},
							{
								name: "Dreifachglas",
								value: "54 mm",
								key: "sticla_3",
							},
						],
						list: [
							"Float + Low-e (+ Argon)",
							"4S",
							"3-1-3",
							"Warm Edge Spacer",
							"Innensprossen (für ein klassisches Design)",
						],
					},
				],
			},
		},
		wood: {
			furniture: {
				title: "MDF & PAL Möbel",
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Möbel aus lackiertem MDF",
							description:
								"Unsere lackierten MDF-Möbel kombinieren Kunst, Qualität und Funktionalität in einem harmonischen Ensemble und bieten eine langlebige und ästhetische Lösung für jeden Raum in Ihrem Zuhause. Dank der kundenspezifischen Produktion können wir Ihnen bei der Einrichtung jedes Raumes in Ihrem Haus helfen, egal ob Sie Möbel für die Küche, das Wohnzimmer, das Schlafzimmer oder das Badezimmer suchen. Mit CNC-Technologie können wir jedes Möbelstück perfekt an das Thema Ihres Raumes anpassen.",
							image: "mobila_mdf",
							specifications: [
								{
									name: "MDF",
									value: "roh",
									key: "mdf_brut",
								},
								{
									name: "Plattendicke",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "Beschläge",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Adler Farbe",
									value: "Acryl / Polyurethan",
									key: "vopsea",
								},
								{
									name: "Melamin Arbeitsplatte",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "Rahmen",
									value: "dekorativ",
									key: "cornisa",
								},
							],
						},
					],
				},
				pal: {
					name: "PAL",
					items: [
						{
							name: "Möbel aus PAL",
							description:
								"Unsere PAL-Möbel kombinieren Qualität, dank des hochdichten PAL und der erstklassigen Beschläge, mit Minimalismus, dank der geraden Kanten und matten oder melaminbeschichteten Oberflächen, und bieten eine langlebige und moderne Lösung für jeden Raum in Ihrem Zuhause. Dank der kundenspezifischen Produktion können wir Ihnen bei der Einrichtung jedes Raumes in Ihrem Haus helfen, egal ob Sie Möbel für die Küche, das Wohnzimmer, das Schlafzimmer oder das Badezimmer suchen.",
							specifications: [
								{
									name: "PAL",
									value: "Egger / Kastamonu",
									key: "mdf_brut",
								},
								{
									name: "Plattendicke",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "Beschläge",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Melamin Arbeitsplatte",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "ABS-Kante",
									value: "2 mm",
									key: "cant_gros",
								},
							],
						},
					],
				},
			},
			doors: {
				title: "Holztüren & MDF-Türen",
				wood: {
					name: "Holz",
					items: [
						{
							name: "Massivholztüren",
							description:
								"Jede Tür aus unserem Lindenholz-Sortiment ist ein erstklassiges Beispiel für Handwerkskunst, das Langlebigkeit und einen über die Jahre anhaltenden Stil garantiert. Dank unserer Erfahrung in der Verarbeitung von Massivholz und unserer rigorosen Auswahl der Rohstoffe bieten wir Türen, die nicht nur dem Verschleiß standhalten, sondern auch die Ästhetik jedes Haustyps perfekt ergänzen, sei es modern oder klassisch.",
							image: "usa_lemn",
							specifications: [
								{
									name: "Massivholz",
									value: "Linde",
									key: "suprafata_furniruita",
								},
								{
									name: "Rahmen",
									value: "Linde",
									key: "imbracaminte_perete",
								},
								{
									name: "Lindenverkleidung",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "Rahmen",
									value: "dekorativ",
									key: "cornisa",
								},
								{
									name: "Adler Farbe",
									value: "Acryl / Polyurethan",
									key: "vopsea",
								},
								{
									name: "Adler Lack",
									value: "Acryl / Polyurethan",
									key: "suprafata_vopsita",
								},
								{
									name: "Schwelle",
									value: "automatisch",
									key: "prag_automat",
								},
							],
						},
					],
				},
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Türen aus rohem MDF",
							description:
								"Die rohen MDF-Plattentüren in unserem Portfolio sind darauf ausgelegt, den ästhetischen und funktionalen Anforderungen von Kunden gerecht zu werden, die die Qualität einer Massivholztür zu einem erschwinglicheren Preis wünschen. Die CNC-Technologie ermöglicht es uns, das Design an jeden architektonischen Stil anzupassen und so beispiellose Flexibilität zu bieten, um die perfekte Atmosphäre in Ihrem Zuhause zu schaffen, unabhängig von seinem Thema.",
							image: "usa_mdf",
							specifications: [
								{
									name: "MDF",
									value: "roh",
									key: "mdf_brut",
								},
								{
									name: "Plattendicke",
									value: "40 mm",
									key: "grosime_panou",
								},
								{
									name: "Lindenverkleidung",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "Rahmen",
									value: "MDF 18 mm",
									key: "imbracaminte_perete",
								},
								{
									name: "Rahmen",
									value: "dekorativ",
									key: "cornisa",
								},
								{
									name: "Adler Farbe",
									value: "Acryl / Polyurethan",
									key: "vopsea",
								},
								{
									name: "Schwelle",
									value: "automatisch",
									key: "prag_automat",
								},
							],
						},
					],
				},
			},
		},
	},
};
