export default {
	interface: {
		wantOffer: "Voglio un’offerta",
		contactUs: "Contattaci",
		sendMessage: "Invia messaggio",
		goToGallery: "Vedi galleria",
		factory: "Fabbrica",
		office: "Ufficio",
	},
	tags: {
		title: "ThermoArt Design - Falegnameria in PVC & Mobili in MDF",
		description:
			"Costruisci la tua casa con noi! Produttore di falegnameria in PVC e alluminio, mobili in MDF e truciolato, porte in MDF e altro ancora.",
	},
	header: {
		home: "Home",
		pvcAluminum: "Infissi in PVC & Alluminio",
		windows: "Finestre",
		doors: "Porte",
		others: "Altri",
		aluminum: "Alluminio",
		interiorDoors: "Porte interne",
		exteriorDoors: "Porte esterne",
		slidingDoors: "Porte scorrevoli",
		aluminumJoinery: "Carpenteria in alluminio",
		glass: "Vetro",
		rollers: "Tapparelle",
		garageDoors: "Porte da garage",
		furnitureDoors: "Mobili & Porte",
		furniture: "Arredamento",
		mdfFurniture: "Mobili in MDF",
		palFurniture: "Mobili in PAL",
		woodDoors: "Porte in legno",
		mdfDoors: "Porte in MDF",
		careers: "Carriere",
		partners: "Partner",
		contact: "Contatto",
	},
	footer: {
		findOutMore: "Vuoi saperne di più su di noi e sui nostri prodotti?",
		partOfTeam: "Vuoi far parte del nostro team?",
		ourPartner: "Vuoi essere nostro partner?",
		followUs: "Seguici",
		discoverUs: "Scoprirci",
	},
	homepage: {
		hero_pvc: "Infissi in PVC & Alluminio",
		hero_lemn: "Mobili & Porte Personalizzate",
		about_us: "Il tuo desiderio, la nostra innovazione",
		about_us_content:
			"Con oltre 20 anni di esperienza e un team di oltre 40 persone, trasformiamo la tua casa ideale in realtà con i nostri prodotti su misura!",
		counter: {
			over: "oltre",
			years: "anni di esperienza",
			clients: "nuovi clienti",
			orders: "ordini unici",
		},
		discover: "Scoprirci",
		discover_content:
			"La nostra azienda è stata fondata nel 2002, come risultato di un decennio di esperienza e passione nell'industria del legno. Da allora, siamo costantemente evoluti, migliorando i processi e implementando le ultime tendenze e tecnologie. Nel 2008, abbiamo istituito la nostra sezione in PVC e alluminio, che ha portato alla creazione del marchio ThermoArt Design, che combina maestria ed esperienza nell'industria del legno con innovazione e tecnologia nella carpenteria in PVC.\n\nEssendo produttori, il cliente tratta direttamente con noi, e le sue esigenze sono la nostra priorità nella creazione del prodotto, dalla consulenza e progettazione alla produzione e installazione. Creatività, innovazione e rispetto per il cliente, oltre alla nostra gamma di prodotti, dalla carpenteria in PVC e alluminio alle porte in legno massiccio e MDF, ai mobili in truciolato, MDF e legno massiccio, ci rendono la scelta perfetta per il completamento della tua casa.",
	},
	careers: {
		offer: "Cosa offriamo?",
		expectation: "Cosa ci aspettiamo?",
		pitchPhrase: "Vuoi far parte del nostro team? Conosciamoci meglio!",
		details:
			"Da ThermoArt Design, siamo sempre desiderosi di lavorare con persone impegnate e desiderose di migliorare!\nSiamo un team di oltre 40 colleghi di varie età e in molteplici posizioni, ma la cosa più importante è che siamo tutti colleghi prima di qualsiasi gerarchia. Non promettiamo di essere una famiglia, ma un collettivo unito in un'atmosfera piacevole.\n\nNon vediamo l'ora di incontrarti, indipendentemente dalla posizione che stai cercando, che tu sia un rappresentante di vendita, un falegname o un installatore.",
		detailList: [],
		offerList: [
			"Stipendio motivante",
			"Prodotti a prezzo ridotto",
			"Attrezzature di alto livello",
			"Opportunità di crescita",
		],
		expectationList: ["Serietà", "Impegno", "Desiderio di crescita"],
	},
	partners: {
		extern: "Esterno",
		intern: "Interno",
		externContent:
			"Con oltre 20 anni di esperienza e partner in diversi paesi come Belgio, Italia, Regno Unito, Francia e oltre, siamo sempre aperti all'ampliamento della nostra rete di partner per portare la qualità oltre i confini. I nostri numerosi ordini per clienti nei paesi occidentali confermano la nostra serietà e gli standard europei ai quali aderiamo.\nPertanto, se stai cercando una crescita sostenibile con un focus sulle esigenze del cliente, cresciamo insieme!",
		internContent:
			"Situati nel cuore della Moldavia (Neamț), siamo sempre aperti alla collaborazione con rivenditori e installatori di tutta la regione. Siamo un team caratterizzato da professionalità e serietà, cosa che può anche essere dedotta dalla nostra presenza sul mercato per oltre due decenni.\nSe vuoi unirti a una partnership in cui la qualità e la soddisfazione del cliente sono i principi base, contattaci e cresciamo insieme!",
		pitchPhrase: "Vuoi essere nostro partner? Contattaci e lavoriamo insieme!",
		intro:
			"Crediamo in uno sviluppo sostenibile, il che non è possibile senza un team unito. Unisciti a noi in una rete nazionale e internazionale di distributori per crescere insieme!",
	},
	contact: {
		name: "Nome",
		city: "Città",
		phone: "Telefono",
		message: "Messaggio",
		messagePlaceholder: "Come possiamo aiutarti?",
		nameReq: "Il nome è obbligatorio!",
		cityReq: "La città è obbligatoria!",
		phoneReq: "Il telefono è obbligatorio!",
		invalidPhone: "Numero non valido!",
		messageTooLong: "Messaggio troppo lungo!",
		tooShort: "Troppo corto!",
		tooLong: "Troppo lungo!",
	},
	products: {
		pvc_alu: {
			windows: {
				title: "Finestre in PVC",
				rehau: {
					name: "Rehau",
					items: [
						{
							name: "Rehau SYNEGO",
							description:
								"Il sistema di finestre SYNEGO offre un ambiente caldo e confortevole, contribuendo significativamente alla conservazione dell'ambiente. Con un'efficienza termica migliorata fino al 45%, questo sistema è un'ottima scelta per chi cerca sia comfort che sostenibilità.",
							specifications: [
								{
									name: "camere",
									value: "7",
									key: "camere",
								},
								{
									name: "spessore telaio",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "vetro triplo",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "saldatura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "guarnizione",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "isolamento acustico",
									value: "47 dB",
									key: "izolatie_fonica",
								},
								{
									name: "coefficiente termico",
									value: "0.94 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "antieffrazione",
									value: "classe RC 3",
									key: "antiefractie",
								},
								{
									name: "armatura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				gealan: {
					name: "Gealan",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Il sistema S9000 di GEALAN-FUTURA® stabilisce nuovi standard in termini di comfort ed efficienza energetica. Combina le ultime innovazioni tecnologiche per offrire un isolamento termico e acustico ineguagliabile, oltre a una struttura robusta per finestre di grandi dimensioni con un'anta fino a 2,4 m grazie alla guarnizione STV.\nPer sfruttare al massimo le qualità di questo profilo, il cliente può scegliere l'installazione standard nZEB per case passive, che prevede l'installazione in pretelai o mensole.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "camere",
									value: "6",
									key: "camere",
								},
								{
									name: "spessore telaio",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "vetro triplo",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "saldatura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "guarnizione",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "guarnizione",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "isolamento IKD",
									value: "camera con schiuma",
									key: "profil_spuma",
								},
								{
									name: "coefficiente termico",
									value: "0.89 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armatura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "antieffrazione",
									value: "classe RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan LINEAR",
							description:
								"Il nuovo sistema GEALAN LINEAR ridefinisce gli standard nella progettazione delle finestre, offrendo un'estetica minimalista perfetta per l'architettura moderna. Questo profilo combina le ultime tecnologie con le nuove tendenze del design, risultando ideale sia per nuove costruzioni che per ristrutturazioni.",
							image: "gealan_linear",
							specifications: [
								{
									name: "camere",
									value: "6",
									key: "camere",
								},
								{
									name: "spessore del telaio",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "vetro triplex",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "saldatura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "guarnizione",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "guarnizione",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "coefficiente termico",
									value: "1 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "rinforzo",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "antieffrazione",
									value: "classe RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Il sistema S8000 di GEALAN è un equilibrio tra economia e prestazioni. Progettato per efficienza e stabilità, questo sistema permette di realizzare finestre di grandi dimensioni senza sacrificare la qualità. Una soluzione intelligente per chi cerca comfort e durata a un prezzo vantaggioso.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "camere",
									value: "5",
									key: "camere",
								},
								{
									name: "spessore telaio",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "vetro triplo",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "saldatura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "coefficiente termico",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armatura",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "antieffrazione",
									value: "classe RC 3",
									key: "antiefractie",
								},
							],
						},
					],
				},
			},
			pvc_doors: {
				title: "Porte in PVC",
				exterior: {
					name: "Esterno",
					items: [
						{
							name: "Gealan S9000",
							description:
								"Le porte esterne S9000 di GEALAN-FUTURA® stabiliscono nuovi standard in termini di comfort ed efficienza energetica. Questo sistema combina la tecnologia più recente per offrire un isolamento termico e acustico impareggiabile, oltre a una struttura robusta anche per dimensioni impressionanti, grazie alla guarnizione STV.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "Camere",
									value: "6",
									key: "camere",
								},
								{
									name: "Spessore cornice",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "Vetro triplo",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "Saldatura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Guarnizione",
									value: "tripla",
									key: "garnitura_tripla",
								},
								{
									name: "Guarnizione",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "Isolamento IKD",
									value: "Camera di schiuma",
									key: "profil_spuma",
								},
								{
									name: "Ferramenta",
									value: "Hoppe",
									key: "feronerie",
								},
								{
									name: "Armatura",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protezione anti-effrazione",
									value: "Classe RC 3",
									key: "antiefractie",
								},
								{
									name: "Bloccaggio",
									value: "multi punti",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armatura",
									value: "prefabbricata",
									key: "armatura_dubla",
								},
								{
									name: "Armatura",
									value: "saldato",
									key: "sudura",
								},
								{
									name: "Serratura",
									value: "elettrica",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"Le porte esterne S8000 di GEALAN offrono un equilibrio tra economia e prestazioni, ed sono una scelta saggia per coloro che cercano comfort e durata a un prezzo abbordabile.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "Camere",
									value: "5",
									key: "camere",
								},
								{
									name: "Spessore cornice",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Vetro triplo",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "Saldatura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Coefficiente termico",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Armatura",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protezione anti-effrazione",
									value: "Classe RC 3",
									key: "antiefractie",
								},
								{
									name: "Bloccaggio",
									value: "multi punti",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armatura",
									value: "prefabbricata",
									key: "armatura_dubla",
								},
								{
									name: "Armatura",
									value: "saldato",
									key: "sudura",
								},
								{
									name: "Serratura",
									value: "elettrica",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Rehau SYNEGO",
							description:
								"Le porte esterne Synego creano un ambiente accogliente e confortevole mentre contribuiscono significativamente alla protezione dell'ambiente. Con un miglioramento dell'efficienza termica fino al 45%, questo sistema è una scelta eccellente per chi vuole sia comfort che durata.",
							specifications: [
								{
									name: "Camere",
									value: "7",
									key: "camere",
								},
								{
									name: "Spessore cornice",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "Vetro triplo",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Saldatura",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Coefficiente termico",
									value: "1.0 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "Protezione anti-effrazione",
									value: "Classe RC 3",
									key: "antiefractie",
								},
								{
									name: "Bloccaggio",
									value: "multi punti",
									key: "incuietoare_antiefractie",
								},
								{
									name: "Armatura",
									value: "prefabbricata",
									key: "armatura_dubla",
								},
								{
									name: "Armatura",
									value: "saldato",
									key: "sudura",
								},
								{
									name: "Serratura",
									value: "elettrica",
									key: "broasca_electrica",
								},
							],
						},
					],
				},
				interior: {
					name: "Interni",
					items: [
						{
							name: "Gealan S8000",
							description:
								"Il sistema S8000 di GEALAN è perfetto per le porte interne, offrendo un equilibrio tra risparmio ed eccellente qualità. Per chi preferisce superfici vetrate, diverse opzioni sono disponibili, oppure puoi creare il tuo design per dare un tocco unico alla tua casa.",
							specifications: [
								{
									name: "Camere",
									value: "5",
									key: "camere",
								},
								{
									name: "Spessore del telaio",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "Saldature",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Rinforzo",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				sliding: {
					name: "Scorrevoli",
					items: [
						{
							name: "Rehau Synego Slide",
							description:
								"La porta scorrevole Rehau Synego Slide ridefinisce il senso di spazio e natura nella tua casa. Le sue dimensioni impressionanti eliminano le barriere tra interno ed esterno, pur offrendo un'eccezionale tenuta anche in caso di piogge abbondanti. Nonostante le sue dimensioni e la sua stabilità, può essere facilmente aperta e chiusa con un solo dito grazie alle sue robuste ferramenta. È la soluzione ideale per una casa che vuole vivere in armonia con il suo ambiente.",
							image: "rehau_synego_slide",
							specifications: [
								{
									name: "Camere",
									value: "7",
									key: "camere",
								},
								{
									name: "Spessore del telaio",
									value: "110 mm",
									key: "grosime_toc",
								},
								{
									name: "Scorrimento",
									value: "facile",
									key: "usa_glisanta",
								},
								{
									name: "Vetro triplo",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "Ferramenta",
									value: "Roto",
									key: "feronerie",
								},
								{
									name: "Guarnizioni",
									value: "doppie",
									key: "garnitura_dubla",
								},
								{
									name: "Saldature",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "Doppio rinforzo",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "Protezione antintrusione",
									value: "Classe RC 2",
									key: "antiefractie",
								},
								{
									name: "coefficiente termico",
									value: "1.3 W/(m²K)",
									key: "coef_temp_fereastra",
								},
							],
						},
					],
				},
			},
			aluminum_joinery: {
				title: "Infissi in alluminio",
				items: [
					{
						name: "Infissi in alluminio per ogni settore",
						description:
							"Dagli infissi per edifici industriali alle porte scorrevoli interne, fino alle pareti divisorie per spazi commerciali, i nostri infissi soddisfano ogni tua esigenza.",
						specifications: [
							{
								name: "Barriera termica",
								value: "termica",
								key: "bariera_termica",
							},
						],
					},
				],
			},
			garage_doors: {
				title: "Porte del garage",
				items: [
					{
						name: "Porte sezionali",
						description:
							"Le nostre porte sezionali combinano durata e minimalismo. Siano esse per edifici residenziali o industriali, con o senza accesso pedonale, la nostra gamma copre tutte le tue esigenze.",
						specifications: [
							{
								name: "Funzionamento",
								value: "elettrico",
								key: "rulou_electric",
							},
							{
								name: "Spessore",
								value: "40 mm",
								key: "grosime_panou",
							},
						],
					},
				],
			},
			rollers: {
				title: "Tapparelle",
				items: [
					{
						name: "Tapparelle in alluminio",
						description:
							"Le nostre tapparelle in alluminio offrono maggiore comfort, isolamento termico e acustico grazie a lame riempite di schiuma, necessarie in un clima sempre più instabile. Possono essere montate sopra le finestre o anche integrate nell'isolamento per un design elegante e minimalista. Non devi preoccuparti delle zanzariere, poiché le tapparelle possono essere dotate di zanzariere.",
						specifications: [
							{
								name: "Funzionamento",
								value: "elettrico",
								key: "rulou_electric",
							},
							{
								name: "Verniciatura",
								value: "elettrostatica",
								key: "suprafata_vopsita",
							},
							{
								name: "Lame",
								value: "riempite di schiuma",
								key: "mdf_brut",
							},
						],
					},
				],
			},
			glass: {
				title: "Vetro",
				items: [
					{
						name: "Vetro della nostra gamma",
						description:
							"Offriamo una vasta gamma di tipi di vetro, a seconda delle tue esigenze e desideri. Ecco un elenco delle configurazioni più comunemente utilizzate:",
						specifications: [
							{
								name: "Vetro",
								value: "temperato",
								key: "densitate",
							},
							{
								name: "Vetro",
								value: "laminato",
								key: "densitate",
							},
							{
								name: "Float + Low-e (+ Argon)",
								value: "Termico",
								key: "coef_temp_sticla",
							},
							{
								name: "Trattato",
								value: "termicamente",
								key: "coef_temp_sticla",
							},
							{
								name: "Vetro triplo",
								value: "54 mm",
								key: "sticla_3",
							},
						],
						list: [
							"Float + Low-e (+ Argon)",
							"4S",
							"3-1-3",
							"Interspazio termico",
							"Aste interne (per un design classico)",
						],
					},
				],
			},
		},
		wood: {
			furniture: {
				title: "Mobili in MDF & PAL",
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Mobili in MDF laccato",
							description:
								"I nostri mobili in MDF laccato combinano arte, qualità e funzionalità in un insieme armonioso e offrono una soluzione duratura ed estetica per ogni stanza della tua casa. Grazie alla produzione su misura, possiamo aiutarti ad arredare ogni stanza della tua casa, che tu stia cercando mobili per la cucina, il soggiorno, la camera da letto o il bagno. Con la tecnologia CNC, possiamo adattare ogni mobile perfettamente al tema del tuo spazio.",
							image: "mobila_mdf",
							specifications: [
								{
									name: "MDF",
									value: "grezzo",
									key: "mdf_brut",
								},
								{
									name: "Spessore del pannello",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "Ferramenta",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Pittura Adler",
									value: "Acrilico / Poliuretano",
									key: "vopsea",
								},
								{
									name: "Top in melamina",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "Cornice",
									value: "decorativa",
									key: "cornisa",
								},
							],
						},
					],
				},
				pal: {
					name: "PAL",
					items: [
						{
							name: "Mobili in PAL",
							description:
								"I nostri mobili in PAL combinano qualità, grazie al PAL ad alta densità e alla ferramenta di prima qualità, con minimalismo, grazie ai bordi retti e alle superfici opache o melaminate, e offrono una soluzione duratura e moderna per ogni stanza della tua casa. Grazie alla produzione su misura, possiamo aiutarti ad arredare ogni stanza della tua casa, che tu stia cercando mobili per la cucina, il soggiorno, la camera da letto o il bagno.",
							specifications: [
								{
									name: "PAL",
									value: "Egger / Kastamonu",
									key: "mdf_brut",
								},
								{
									name: "Spessore del pannello",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "Ferramenta",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Top in melamina",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "Bordo ABS",
									value: "2 mm",
									key: "cant_gros",
								},
							],
						},
					],
				},
			},
			doors: {
				title: "Porte in legno & MDF",
				wood: {
					name: "Legno",
					items: [
						{
							name: "Porte in legno massello",
							description:
								"Ogni porta della nostra gamma in legno di tiglio è un esempio eccezionale di artigianato che garantisce durata e uno stile che perdura nel tempo. Grazie alla nostra esperienza nella lavorazione del legno massello e alla nostra rigorosa selezione delle materie prime, offriamo porte che resistono non solo all'usura, ma che completano perfettamente l'estetica di qualsiasi tipo di casa, sia essa moderna o classica.",
							image: "usa_lemn",
							specifications: [
								{
									name: "Legno massello",
									value: "Tiglio",
									key: "suprafata_furniruita",
								},
								{
									name: "Cornice",
									value: "Tiglio",
									key: "imbracaminte_perete",
								},
								{
									name: "Rivestimento in tiglio",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "Cornice",
									value: "decorativa",
									key: "cornisa",
								},
								{
									name: "Pittura Adler",
									value: "Acrilico / Poliuretano",
									key: "vopsea",
								},
								{
									name: "Vernice Adler",
									value: "Acrilico / Poliuretano",
									key: "suprafata_vopsita",
								},
								{
									name: "Soglia",
									value: "automatica",
									key: "prag_automat",
								},
							],
						},
					],
				},
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Porte in MDF grezzo",
							description:
								"Le porte in pannelli di MDF grezzo del nostro portfolio sono progettate per soddisfare le esigenze estetiche e funzionali dei clienti che desiderano la qualità di una porta in legno massello a un prezzo più conveniente. La tecnologia CNC ci permette di adattare il design a ogni stile architettonico, offrendo una flessibilità senza precedenti per creare l'atmosfera perfetta nella tua casa, qualunque sia il suo tema.",
							image: "usa_mdf",
							specifications: [
								{
									name: "MDF",
									value: "grezzo",
									key: "mdf_brut",
								},
								{
									name: "Spessore del pannello",
									value: "40 mm",
									key: "grosime_panou",
								},
								{
									name: "Rivestimento in tiglio",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "Cornice",
									value: "MDF 18 mm",
									key: "imbracaminte_perete",
								},
								{
									name: "Cornice",
									value: "decorativa",
									key: "cornisa",
								},
								{
									name: "Pittura Adler",
									value: "Acrilico / Poliuretano",
									key: "vopsea",
								},
								{
									name: "Soglia",
									value: "automatica",
									key: "prag_automat",
								},
							],
						},
					],
				},
			},
		},
	},
};
