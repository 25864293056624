import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ProductHeader from "../components/ProductHeader.js";
import ProductSwitch from "../components/ProductSwitch.js";
import {
	getSubcategoryDetails,
	getSubcategoryFromPath,
	pathToTranslation,
	useShouldScrollUp,
} from "../utils/helpers.js";

export default function ProductLayout() {
	const location = useLocation();
	const shouldScrollUp = useShouldScrollUp();
	const [subcategoryDetails, setSubcategoryDetails] = useState(
		getSubcategoryDetails("windows")
	);
	const [transKey, setTransKey] = useState("windows");
	const [galleryKey, setGalleryKey] = useState("windows");

	useEffect(() => {
		let tempSubcategory = getSubcategoryFromPath(location.pathname);
		let tempCategory = pathToTranslation(location.pathname, false);
		let tempGallery = pathToTranslation(location.pathname);

		setSubcategoryDetails(getSubcategoryDetails(tempSubcategory));
		setTransKey(tempCategory);
		setGalleryKey(tempGallery);
	}, [location]);

	useEffect(() => {
		if (shouldScrollUp) {
			window.scrollTo(0, 0);
		}
	}, [shouldScrollUp]);

	return (
		<div>
			<ProductHeader
				subcategory={subcategoryDetails}
				transKey={transKey}
				galleryKey={galleryKey}
			/>
			<ProductSwitch subcategory={subcategoryDetails} transKey={transKey} />
			<Outlet />
		</div>
	);
}
