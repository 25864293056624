import React, { useEffect, useRef, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'

const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
}

export default function Counter({ to=0, canStart, className, from=0, innerRef=null }) {
    
    const ref = useRef(null)
    const [{number}, api] = useSpring(() => ({
        from: { number: 0 },
        config: { mass: 1, tension: 20, friction: 10 }
    }), [])

    const handleIntersection = ([entry]) => {
        if (entry.isIntersecting && canStart) {
            api.start({
                to: {number: to},
            })
        }
    }

    useEffect(() => {
        let observer
        if (!isNaN(to)) {
            observer = new IntersectionObserver(handleIntersection, options)
            if (ref.current) {
                observer.observe(ref.current)
            }
        }
   
        return () => {
            if (ref.current && observer) observer.unobserve(ref.current)
        }
    }, [ref, canStart, to])

    return (
        <div ref={ref}>
            <animated.div className={className}>{number.to((n) => n.toFixed(0))}</animated.div>
        </div>
    )
}
