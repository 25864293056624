export default {
	interface: {
		wantOffer: "Request an Offer",
		contactUs: "Contact Us",
		sendMessage: "Send Message",
		goToGallery: "View Gallery",
		factory: "Factory",
		office: "Office",
	},
	tags: {
		title: "ThermoArt Design - PVC Carpentry & MDF Furniture",
		description:
			"Build your home with us! Manufacturer of PVC and aluminum carpentry, MDF and chipboard furniture, MDF doors and more.",
	},
	header: {
		home: "Home",
		pvcAluminum: "PVC & Aluminium Joinery",
		windows: "Windows",
		doors: "Doors",
		others: "Others",
		aluminum: "Aluminium",
		interiorDoors: "Interior Doors",
		exteriorDoors: "Exterior Doors",
		slidingDoors: "Sliding Doors",
		aluminumJoinery: "Aluminium Joinery",
		glass: "Glass",
		rollers: "Rollers",
		garageDoors: "Garage Doors",
		furnitureDoors: "Furniture & Doors",
		furniture: "Furniture",
		mdfFurniture: "MDF Furniture",
		palFurniture: "Chipboard Furniture",
		woodDoors: "Wooden Doors",
		mdfDoors: "MDF Doors",
		careers: "Careers",
		partners: "Dealers",
		contact: "Contact",
	},
	footer: {
		findOutMore: "Want to know more about us and our products?",
		partOfTeam: "Want to be part of our team?",
		ourPartner: "Want to be our partner?",
		followUs: "Follow Us",
		discoverUs: "Discover Us",
	},
	homepage: {
		hero_pvc: "PVC & Aluminium Joinery",
		hero_lemn: "Customised Furniture & Doors",
		about_us: "Your Wish, Our Innovation",
		about_us_content:
			"With over 20 years of experience and a team of over 40 people, we turn your ideal home into reality through our custom-made products!",
		counter: {
			over: "over",
			years: "years of experience",
			clients: "new clients",
			orders: "unique orders",
		},
		discover: "Discover Us",
		discover_content:
			"Our company was founded in 2002, as a result of a decade of experience and passion in the wood industry. Since then, we have continuously evolved, improving processes and implementing the latest trends and technologies. In 2008, we established our PVC and aluminium section, leading to the creation of the ThermoArt Design brand, which combines mastery and experience in the wood industry with innovation and technology in PVC joinery.\n\nBeing manufacturers, the customer deals directly with us, and their needs are our priority in product creation, from consultation and design to production and installation. Creativity, innovation, and respect for the customer, plus our range of products, from PVC and aluminium joinery to solid wood and MDF doors, to chipboard, MDF, and solid wood furniture, make us the perfect choice for your home fulfilment.",
	},
	careers: {
		offer: "What do we offer?",
		expectation: "What do we expect?",
		pitchPhrase:
			"Want to be part of our team? Let’s get to know each other better!",
		details:
			"At ThermoArt Design, we are always eager to work with committed and aspiring people!\nWe are a team of over 40 colleagues of various ages and in multiple positions, but the most important thing is that we are all colleagues before any hierarchy. We don't promise to be a family, but a united collective in a pleasant atmosphere.\n\nWe look forward to meeting you regardless of the position you are seeking, whether it's a sales representative, carpenter, or installer.",
		detailList: [],
		offerList: [
			"Motivating Salary",
			"Products at Reduced Prices",
			"High-Performance Equipment",
			"Development Opportunities",
		],
		expectationList: ["Reliability", "Commitment", "Desire for Development"],
	},
	partners: {
		extern: "External",
		intern: "Internal",
		externContent:
			"With over 20 years of experience and partners in multiple countries such as Belgium, Italy, the United Kingdom, France, and beyond, we are always open to expanding our network of partners to take quality beyond borders. Our numerous orders for clients in Western countries confirm our seriousness and the European standards to which we adhere.\nTherefore, if you're looking for sustainable growth with a focus on customer needs, let's grow together!",
		internContent:
			"Located in the heart of Moldova (Neamț), we are always open to collaborating with dealers and installers from the entire region. We are a team characterized by professionalism and seriousness, which can also be deduced from our presence in the market for over two decades.\nIf you want to join a partnership where quality and customer satisfaction are the basic principles, contact us and let's grow together!",
		pitchPhrase: "Want to be our partner? Contact us and let’s work together!",
		intro:
			"We believe in sustainable development, which cannot be achieved without a united team.\nJoin us in a national and international network of dealers to grow together!",
	},
	contact: {
		name: "Name",
		city: "City",
		phone: "Phone",
		message: "Message",
		messagePlaceholder: "How can we assist you?",
		nameReq: "Name is required!",
		cityReq: "City is required!",
		phoneReq: "Phone is required!",
		invalidPhone: "Invalid number!",
		messageTooLong: "Message too long!",
		tooShort: "Too short!",
		tooLong: "Too long!",
	},
	products: {
		pvc_alu: {
			windows: {
				title: "PVC Windows",
				rehau: {
					name: "Rehau",
					items: [
						{
							name: "Rehau SYNEGO",
							description:
								"The SYNEGO window system ensures a warm and comfortable environment, while significantly contributing to the conservation of the environment. With improved thermal efficiency of up to 45%, this system is an excellent choice for those who want both comfort and sustainability.",
							specifications: [
								{
									name: "chambers",
									value: "7",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "triplex glass",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "gasket",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "sound insulation",
									value: "47 dB",
									key: "izolatie_fonica",
								},
								{
									name: "thermal coefficient",
									value: "0.94 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "burglar resistance",
									value: "class RC 3",
									key: "antiefractie",
								},
								{
									name: "reinforcement",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				gealan: {
					name: "Gealan",
					items: [
						{
							name: "Gealan S9000",
							description:
								"The S9000 system from GEALAN-FUTURA® sets new standards in comfort and energy efficiency. It combines the latest advancements in technology to provide unparalleled thermal and sound insulation, as well as a robust structure for impressively large windows with a sash up to 2.4 m thanks to the STV gasket.\nTo make the most of the qualities of this profile, the customer can choose the nZEB standard installation for passive houses, which involves installation in pre-frames or consoles.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "chambers",
									value: "6",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "triplex glass",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "gasket",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "gasket",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "IKD insulation",
									value: "foam chamber",
									key: "profil_spuma",
								},
								{
									name: "thermal coefficient",
									value: "0.89 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "reinforcement",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "burglar resistance",
									value: "class RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan LINEAR",
							description:
								"The new GEALAN LINEAR system redefines standards in window design, offering a minimalist aesthetic perfect for modern architecture. This profile combines the latest technologies with new design trends, making it ideal for both new constructions and renovations.",
							image: "gealan_linear",
							specifications: [
								{
									name: "chambers",
									value: "6",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "triplex glass",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "gasket",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "gasket",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "thermal coefficient",
									value: "1 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "reinforcement",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "burglar resistance",
									value: "class RC 3",
									key: "antiefractie",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"The S8000 system from GEALAN is a balance between economy and performance. Designed for efficiency and stability, this system makes large-sized windows possible without sacrificing quality. A smart solution for those who want comfort and durability at an advantageous price.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "chambers",
									value: "5",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "triplex glass",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "thermal coefficient",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "reinforcement",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
								{
									name: "burglar resistance",
									value: "class RC 3",
									key: "antiefractie",
								},
							],
						},
					],
				},
			},
			pvc_doors: {
				title: "PVC Doors",
				exterior: {
					name: "Exterior",
					items: [
						{
							name: "Gealan S9000",
							description:
								"The S9000 exterior doors from GEALAN-FUTURA® set new standards in comfort and energy efficiency. This system combines the latest technology to offer unparalleled thermal and sound insulation, as well as a durable structure at impressive dimensions, thanks to the STV seal.",
							image: "gealan_s9000",
							specifications: [
								{
									name: "chambers",
									value: "6",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "82.5 mm",
									key: "grosime_toc",
								},
								{
									name: "triplex glass",
									value: "54 mm",
									key: "sticla_3",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "seal",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "seal",
									value: "STV",
									key: "garnitura_sticla",
								},
								{
									name: "IKD insulation",
									value: "foam chamber",
									key: "profil_spuma",
								},
								{
									name: "hardware",
									value: "Hoppe",
									key: "feronerie",
								},
								{
									name: "armature",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "burglar protection",
									value: "class RC 3",
									key: "antiefractie",
								},
								{
									name: "locking",
									value: "multi-point",
									key: "incuietoare_antiefractie",
								},
								{
									name: "armature",
									value: "prefabricated",
									key: "armatura_dubla",
								},
								{
									name: "armature",
									value: "welded",
									key: "sudura",
								},
								{
									name: "lock",
									value: "electric",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Gealan S8000",
							description:
								"The S8000 exterior doors from GEALAN offer a balance between economy and performance, being a smart solution for those who want comfort and durability at an affordable price.",
							image: "gealan_s8000",
							specifications: [
								{
									name: "chambers",
									value: "5",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "triplex glass",
									value: "44 mm",
									key: "sticla_3",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "thermal coefficient",
									value: "1.2 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "armature",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "burglar protection",
									value: "class RC 3",
									key: "antiefractie",
								},
								{
									name: "locking",
									value: "multi-point",
									key: "incuietoare_antiefractie",
								},
								{
									name: "armature",
									value: "prefabricated",
									key: "armatura_dubla",
								},
								{
									name: "armature",
									value: "welded",
									key: "sudura",
								},
								{
									name: "lock",
									value: "electric",
									key: "broasca_electrica",
								},
							],
						},
						{
							name: "Rehau SYNEGO",
							description:
								"The Synego exterior doors provide a warm and comfortable atmosphere while significantly contributing to environmental conservation. With improved thermal efficiency of up to 45%, this system is an excellent choice for those who want both comfort and sustainability.",
							specifications: [
								{
									name: "chambers",
									value: "7",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "80 mm",
									key: "grosime_toc",
								},
								{
									name: "triplex glass",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "seal",
									value: "triple",
									key: "garnitura_tripla",
								},
								{
									name: "sound insulation",
									value: "47 dB",
									key: "izolatie_fonica",
								},
								{
									name: "thermal coefficient",
									value: "0.94 W/(m²K)",
									key: "coef_temp_fereastra",
								},
								{
									name: "burglar protection",
									value: "class RC 3",
									key: "antiefractie",
								},
								{
									name: "armature",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "locking",
									value: "multi-point",
									key: "incuietoare_antiefractie",
								},
								{
									name: "armature",
									value: "prefabricated",
									key: "armatura_dubla",
								},
								{
									name: "armature",
									value: "welded",
									key: "sudura",
								},
								{
									name: "lock",
									value: "electric",
									key: "broasca_electrica",
								},
							],
						},
					],
				},
				interior: {
					name: "Interiors",
					items: [
						{
							name: "Gealan S8000",
							description:
								"The S8000 system from GEALAN is perfect for interior doors, offering a balance between economy and quality. Those who desire glazed surfaces can choose from a varied range or customize their own model, bringing their own style to their home.",
							specifications: [
								{
									name: "chambers",
									value: "5",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "74 mm",
									key: "grosime_toc",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "armature",
									value: "1.5 mm",
									key: "armatura_dubla",
								},
							],
						},
					],
				},
				sliding: {
					name: "Sliding",
					items: [
						{
							name: "Rehau Synego Slide",
							description:
								"The Rehau Synego Slide sliding door redefines the notion of space and nature in the comfort of your own home. Its impressive dimensions eliminate the barriers between indoors and outdoors, while its exceptional sealing ensures protection even against torrential rains. Despite its dimensions and strength, thanks to the high-performance hardware, it can be slid with a single finger both from the inside and the outside. It is the ideal solution for a home that aspires to harmony with the surrounding environment.",
							image: "rehau_synego_slide",
							specifications: [
								{
									name: "chambers",
									value: "7",
									key: "camere",
								},
								{
									name: "frame thickness",
									value: "110 mm",
									key: "grosime_toc",
								},
								{
									name: "sliding",
									value: "easy",
									key: "usa_glisanta",
								},
								{
									name: "triplex glass",
									value: "51 mm",
									key: "sticla_3",
								},
								{
									name: "hardware",
									value: "Roto",
									key: "feronerie",
								},
								{
									name: "gasket",
									value: "double",
									key: "garnitura_dubla",
								},
								{
									name: "welding",
									value: "0.2 mm",
									key: "sudura",
								},
								{
									name: "double armature",
									value: "2 mm",
									key: "armatura_dubla",
								},
								{
									name: "burglar-proof",
									value: "class RC 2",
									key: "antiefractie",
								},
								{
									name: "thermal coefficient",
									value: "1.3 W/(m²K)",
									key: "coef_temp_fereastra",
								},
							],
						},
					],
				},
			},
			aluminum_joinery: {
				title: "Aluminum Joinery",
				items: [
					{
						name: "Aluminum joinery for any domain",
						description:
							"From joinery for industrial buildings, sliding interior doors, to curtain walls for commercial spaces, our joinery meets all your needs.",
						specifications: [
							{
								name: "barrier",
								value: "thermal",
								key: "bariera_termica",
							},
						],
					},
				],
			},
			garage_doors: {
				title: "Garage Doors",
				items: [
					{
						name: "Sectional Garage Doors",
						description:
							"Our sectional garage doors combine durability and minimalism. From residential doors to industrial ones, with or without pedestrian access, our range covers all your needs.",
						specifications: [
							{
								name: "operation",
								value: "electric",
								key: "rulou_electric",
							},
							{
								name: "thickness",
								value: "40 mm",
								key: "grosime_panou",
							},
						],
					},
				],
			},
			rollers: {
				title: "Rollers",
				items: [
					{
						name: "Aluminum Rollers",
						description:
							"Our aluminum rollers offer increased comfort, thermally and acoustically insulating thanks to foam-filled slats, becoming necessary in an increasingly unstable climate. They can be overlaid, that is mounted above the windows, even embedded in insulation, for an elegant and minimalist design. Also, you don't have to worry about insect screens, as the rollers can be accessorized with roll-up insect screens.",
						specifications: [
							{
								name: "operation",
								value: "electric",
								key: "rulou_electric",
							},
							{
								name: "painted",
								value: "electrostatic",
								key: "suprafata_vopsita",
							},
							{
								name: "slats",
								value: "with foam",
								key: "mdf_brut",
							},
						],
					},
				],
			},
			glass: {
				title: "Glass",
				items: [
					{
						name: "Glass from our range",
						description:
							"We offer a wide range of types of glass, depending on your needs and desires. Below we present a list with the most commonly used configurations:",
						specifications: [
							{
								name: "glass",
								value: "tempered",
								key: "densitate",
							},
							{
								name: "glass",
								value: "laminated",
								key: "sticla_securizata",
							},
							{
								name: "treated",
								value: "thermally",
								key: "coef_temp_sticla",
							},
							{
								name: "triplex glass",
								value: "54 mm",
								key: "sticla_3",
							},
						],
						list: [
							"Float + Low-e (+ argon)",
							"4S",
							"3-1-3",
							"Warm Edge Spacer",
							"Interior Sashes (for a classic design)",
						],
					},
				],
			},
		},
		wood: {
			furniture: {
				title: "MDF & PAL Furniture",
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Painted MDF furniture",
							description:
								"Our painted MDF furniture combines art, quality, and functionality in a harmonious ensemble, providing a durable and aesthetic solution for every room in your home. Thanks to custom-only production, we can help you furnish every room in your house, whether you're looking for furniture for the kitchen, living room, bedroom, or bathroom. With CNC technology, we can customize each piece of furniture to perfectly match your space's theme.",
							image: "mobila_mdf",
							specifications: [
								{
									name: "MDF",
									value: "raw",
									key: "mdf_brut",
								},
								{
									name: "panel thickness",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "hardware",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "Adler paint",
									value: "acrylic / polyurethane",
									key: "vopsea",
								},
								{
									name: "melamine countertop",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "frame",
									value: "decorative",
									key: "cornisa",
								},
							],
						},
					],
				},
				pal: {
					name: "PAL",
					items: [
						{
							name: "Chipboard furniture",
							description:
								"Our PAL furniture combines quality, due to the high-density PAL and top-notch hardware, with minimalism, thanks to the straight edges and matte or melamine surfaces, providing a durable and modern solution for every room in your home. Thanks to custom-only production, we can help you furnish every room in your house, whether you're looking for furniture for the kitchen, living room, bedroom, or bathroom.",
							specifications: [
								{
									name: "PAL",
									value: "Egger / Kastamonu",
									key: "mdf_brut",
								},
								{
									name: "panel thickness",
									value: "18 mm",
									key: "grosime_panou",
								},
								{
									name: "hardware",
									value: "Blum / Häfele",
									key: "feronerie",
								},
								{
									name: "melamine countertop",
									value: "36 mm",
									key: "grosime_blat",
								},
								{
									name: "ABS edge",
									value: "2 mm",
									key: "cant_gros",
								},
							],
						},
					],
				},
			},
			doors: {
				title: "Wooden & MDF Doors",
				wood: {
					name: "Wood",
					items: [
						{
							name: "Solid wood doors",
							description:
								"Each door in our lime wood range is a high-class example of craftsmanship, guaranteeing durability and a style that lasts over the years. Thanks to our experience in processing solid wood and our rigorous selection of raw materials, we offer doors that not only withstand wear but also perfectly complement the aesthetics of any type of home, be it modern or classic.",
							image: "usa_lemn",
							specifications: [
								{
									name: "Solid Wood",
									value: "lime",
									key: "suprafata_furniruita",
								},
								{
									name: "frame",
									value: "lime",
									key: "imbracaminte_perete",
								},
								{
									name: "lime casing",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "frame",
									value: "decorative",
									key: "cornisa",
								},
								{
									name: "Adler paint",
									value: "acrylic / polyurethane",
									key: "vopsea",
								},
								{
									name: "Adler lacquer",
									value: "acrylic / polyurethane",
									key: "suprafata_vopsita",
								},
								{
									name: "threshold",
									value: "automatic",
									key: "prag_automat",
								},
							],
						},
					],
				},
				mdf: {
					name: "MDF",
					items: [
						{
							name: "Doors made from raw MDF",
							description:
								"The raw MDF panel doors in our portfolio are designed to meet the aesthetic and functional requirements of customers who desire the quality of a solid wood door at a more affordable price. CNC technology allows us to adapt the design to any architectural style, thus offering unparalleled flexibility to create the perfect ambiance in your home, regardless of its theme.",
							image: "usa_mdf",
							specifications: [
								{
									name: "MDF",
									value: "raw",
									key: "mdf_brut",
								},
								{
									name: "panel thickness",
									value: "40 mm",
									key: "grosime_panou",
								},
								{
									name: "lime casing",
									value: "80 x 43 mm",
									key: "suprafata_furniruita",
								},
								{
									name: "frame",
									value: "MDF 18 mm",
									key: "imbracaminte_perete",
								},
								{
									name: "frame",
									value: "decorative",
									key: "cornisa",
								},
								{
									name: "Adler paint",
									value: "acrylic / polyurethane",
									key: "vopsea",
								},
								{
									name: "threshold",
									value: "automatic",
									key: "prag_automat",
								},
							],
						},
					],
				},
			},
		},
	},
};
