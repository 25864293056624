import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { COLORS, FONT_SIZES } from '../theme'

export default function NotFound() {
  const navigate = useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/')
    }, 1500)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className='container partners' style={{display: 'block', paddingBottom: 120}}>
      <h2 style={{textAlign: 'left', marginTop: 150, marginBottom: 20}}>Page not found</h2>
      <h2 style={{textAlign: 'left', fontSize: FONT_SIZES.large, color: COLORS.black, marginBottom: 50}}>We are redirecting you to Homepage...</h2>
      <Link to='/' style={{fontWeight: 'bold', fontSize: FONT_SIZES.large ,color: COLORS.black}}>{'Go to Homepage >'}</Link>
    </div>
  )
}
