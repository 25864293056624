import antiefractie from "../assets/specs/antiefractie.svg";
import antiefractie_white from "../assets/specs/antiefractie_white.svg";
import armatura_dubla from "../assets/specs/armatura_dubla.svg";
import armatura_dubla_white from "../assets/specs/armatura_dubla_white.svg";
import bariera_termica from "../assets/specs/bariera_termica.svg";
import bariera_termica_white from "../assets/specs/bariera_termica_white.svg";
import broasca_electrica from "../assets/specs/broasca_electrica.svg";
import broasca_electrica_white from "../assets/specs/broasca_electrica_white.svg";
import camere from "../assets/specs/camere.svg";
import camere_white from "../assets/specs/camere_white.svg";
import cant_gros from "../assets/specs/cant_gros.svg";
import cant_gros_white from "../assets/specs/cant_gros_white.svg";
import coef_temp_fereastra from "../assets/specs/coef_temp_fereastra.svg";
import coef_temp_fereastra_white from "../assets/specs/coef_temp_fereastra_white.svg";
import coef_temp_profil from "../assets/specs/coef_temp_profil.svg";
import coef_temp_profil_white from "../assets/specs/coef_temp_profil_white.svg";
import coef_temp_sticla from "../assets/specs/coef_temp_sticla.svg";
import coef_temp_sticla_white from "../assets/specs/coef_temp_sticla_white.svg";
import densitate from "../assets/specs/densitate.svg";
import densitate_white from "../assets/specs/densitate_white.svg";
import fereastra from "../assets/specs/fereastra.svg";
import fereastra_white from "../assets/specs/fereastra_white.svg";
import garnitura_dubla from "../assets/specs/garnitura_dubla.svg";
import garnitura_dubla_white from "../assets/specs/garnitura_dubla_white.svg";
import garnitura_sticla from "../assets/specs/garnitura_sticla.svg";
import garnitura_sticla_white from "../assets/specs/garnitura_sticla_white.svg";
import garnitura_tripla from "../assets/specs/garnitura_tripla.svg";
import garnitura_tripla_white from "../assets/specs/garnitura_tripla_white.svg";
import geam_olandez from "../assets/specs/geam_olandez.svg";
import geam_olandez_white from "../assets/specs/geam_olandez_white.svg";
import grosime_blat from "../assets/specs/grosime_blat.svg";
import grosime_blat_white from "../assets/specs/grosime_blat_white.svg";
import grosime_panou from "../assets/specs/grosime_panou.svg";
import grosime_panou_white from "../assets/specs/grosime_panou_white.svg";
import grosime_toc from "../assets/specs/grosime_toc.svg";
import grosime_toc_white from "../assets/specs/grosime_toc_white.svg";
import incuietoare_antiefractie from "../assets/specs/incuietoare_antiefractie.svg";
import incuietoare_antiefractie_white from "../assets/specs/incuietoare_antiefractie_white.svg";
import izolatie_fonica from "../assets/specs/izolatie_fonica.svg";
import izolatie_fonica_white from "../assets/specs/izolatie_fonica_white.svg";
import mdf_brut from "../assets/specs/mdf_brut.svg";
import mdf_brut_white from "../assets/specs/mdf_brut_white.svg";
import prag_automat from "../assets/specs/prag_automat.svg";
import prag_automat_white from "../assets/specs/prag_automat_white.svg";
import profil_spuma from "../assets/specs/profil_spuma.svg";
import profil_spuma_white from "../assets/specs/profil_spuma_white.svg";
import rulou_electric from "../assets/specs/rulou_electric.svg";
import rulou_electric_white from "../assets/specs/rulou_electric_white.svg";
import sticla from "../assets/specs/sticla.svg";
import sticla_2 from "../assets/specs/sticla_2.svg";
import sticla_2_white from "../assets/specs/sticla_2_white.svg";
import sticla_3 from "../assets/specs/sticla_3.svg";
import sticla_3_white from "../assets/specs/sticla_3_white.svg";
import sticla_securizata from "../assets/specs/sticla_securizata.svg";
import sticla_securizata_white from "../assets/specs/sticla_securizata_white.svg";
import sticla_white from "../assets/specs/sticla_white.svg";
import sudura from "../assets/specs/sudura.svg";
import sudura_white from "../assets/specs/sudura_white.svg";
import suprafata_furniruita from "../assets/specs/suprafata_furniruita.svg";
import suprafata_furniruita_white from "../assets/specs/suprafata_furniruita_white.svg";
import suprafata_melaminata from "../assets/specs/suprafata_melaminata.svg";
import suprafata_melaminata_white from "../assets/specs/suprafata_melaminata_white.svg";
import suprafata_vopsita from "../assets/specs/suprafata_vopsita.svg";
import suprafata_vopsita_white from "../assets/specs/suprafata_vopsita_white.svg";
import test from "../assets/specs/test.svg";
import test_active from "../assets/specs/test_active.svg";
import usa_glisanta from "../assets/specs/usa_glisanta.svg";
import usa_glisanta_white from "../assets/specs/usa_glisanta_white.svg";
import vopsea from "../assets/specs/vopsea.svg";
import vopsea_white from "../assets/specs/vopsea_white.svg";
import feronerie from "../assets/specs/feronerie.svg";
import feronerie_white from "../assets/specs/feronerie_white.svg";
import cornisa from "../assets/specs/cornisa.svg";
import cornisa_white from "../assets/specs/cornisa_white.svg";
import imbracaminte_perete from "../assets/specs/imbracaminte_perete.svg";
import imbracaminte_perete_white from "../assets/specs/imbracaminte_perete_white.svg";
import gealan_s9000 from "../assets/products/gealan_s9000.png";
import gealan_s8000 from "../assets/products/gealan_s8000.png";
import gealan_linear from "../assets/products/gealan_linear.png";
import usa_lemn from "../assets/products/usa_lemn.png";
import usa_mdf from "../assets/products/usa_mdf.png";
import mobila_mdf from "../assets/products/mobila_mdf.png";
import rehau_synego_slide from "../assets/products/rehau_synego_slide.png";

export default {
	logo: require("../assets/logo.png"),
	logo_white: require("../assets/logo_white.png"),
	logo_sign: require("../assets/logo_sign.png"),
	windows: require("../assets/windows.jpeg"),
	windows_placeholder: require("../assets/windows-placeholder.jpeg"),
	pvc_doors: require("../assets/pvc_doors.jpeg"),
	furnitureHero: require("../assets/furniture.jpeg"),
	rollers: require("../assets/rollers.jpeg"),
	glass: require("../assets/glass.jpeg"),
	garage_doors: require("../assets/garage_doors.jpeg"),
	wood_doors: require("../assets/wood-door.jpg"),
	aluminum_joinery: require("../assets/aluminum-joinery.jpeg"),
	careers: require("../assets/careers_pvc.jpeg"),
	antiefractie,
	antiefractie_white,
	armatura_dubla,
	armatura_dubla_white,
	bariera_termica,
	bariera_termica_white,
	broasca_electrica,
	broasca_electrica_white,
	camere,
	camere_white,
	cant_gros,
	cant_gros_white,
	coef_temp_fereastra,
	coef_temp_fereastra_white,
	coef_temp_profil,
	coef_temp_profil_white,
	coef_temp_sticla,
	coef_temp_sticla_white,
	densitate,
	densitate_white,
	fereastra,
	fereastra_white,
	garnitura_dubla,
	garnitura_dubla_white,
	garnitura_sticla,
	garnitura_sticla_white,
	garnitura_tripla,
	garnitura_tripla_white,
	geam_olandez,
	geam_olandez_white,
	grosime_blat,
	grosime_blat_white,
	grosime_panou,
	grosime_panou_white,
	grosime_toc,
	grosime_toc_white,
	incuietoare_antiefractie,
	incuietoare_antiefractie_white,
	izolatie_fonica,
	izolatie_fonica_white,
	mdf_brut,
	mdf_brut_white,
	prag_automat,
	prag_automat_white,
	profil_spuma,
	profil_spuma_white,
	rulou_electric,
	rulou_electric_white,
	sticla,
	sticla_2,
	sticla_2_white,
	sticla_3,
	sticla_3_white,
	sticla_securizata,
	sticla_securizata_white,
	sticla_white,
	sudura,
	sudura_white,
	suprafata_furniruita,
	suprafata_furniruita_white,
	suprafata_melaminata,
	suprafata_melaminata_white,
	suprafata_vopsita,
	suprafata_vopsita_white,
	test,
	test_active,
	usa_glisanta,
	usa_glisanta_white,
	vopsea,
	vopsea_white,
	feronerie,
	feronerie_white,
	cornisa,
	cornisa_white,
	imbracaminte_perete,
	imbracaminte_perete_white,
	//GALLERY:
	pvc_alu: {
		windows: require.context("../assets/gallery/pvc_windows"),
		glass: require.context("../assets/gallery/sticla"),
		pvc_doors: require.context("../assets/gallery/pvc_doors"),
		rollers: require.context("../assets/gallery/rulouri"),
		garage_doors: require.context("../assets/gallery/usi_garaj"),
		aluminum_joinery: require.context("../assets/gallery/aluminiu"),
	},
	furniture: {
		mdf: require.context("../assets/gallery/mobila_mdf"),
		pal: require.context("../assets/gallery/mobila_pal"),
		wood_doors: require.context("../assets/gallery/usi_lemn"),
		mdf_doors: require.context("../assets/gallery/usi_mdf"),
	},
	doors: {
		wood: require.context("../assets/gallery/usi_lemn"),
		mdf: require.context("../assets/gallery/usi_mdf"),
	},
	products: {
		gealan_s9000,
		gealan_s8000,
		gealan_linear,
		usa_lemn,
		usa_mdf,
		mobila_mdf,
		rehau_synego_slide,
	},
};
