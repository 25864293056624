import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";
import Footer from "../components/Footer.js";
import { BiArrowBack } from "react-icons/bi";
import { COLORS } from "../theme";
import { useTranslation } from "react-i18next";
import images from "../theme/images.js";
import { useTracking } from "../utils/useTracking.js";

export default function Gallery() {
	useTracking();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	const [galleryImages, setGalleryImages] = useState([]);
	const galleryParam = searchParams.get("gallery");
	const categoryParam = searchParams.get("category");
	const isWood = galleryParam.split(".")[0] === "wood";

	useEffect(() => {
		const keys = galleryParam.split(".");
		let imagesToDisplay =
			keys[0] === "wood" ? images[keys[1]][keys[2]] : images[keys[0]][keys[1]];
		if (imagesToDisplay) {
			const imageList = imagesToDisplay
				.keys()
				.map((image) => imagesToDisplay(image));
			setGalleryImages(imageList);
		}
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const computeHeight = () => {
		return windowSize.height * 0.8;
	};

	return (
		<div className='gallery-container'>
			<div className='header-container gallery-header shadow'>
				<div className='back-container' onClick={() => navigate(-1)}>
					<BiArrowBack color={COLORS.white} size={22} />
				</div>
				<p id='title'>
					{isWood
						? t(`products.${galleryParam}.name`)
						: t(`products.${categoryParam}.title`)}
				</p>
				<Link to={"/contact"} id='gallery-contact'>
					{t("interface.contactUs")}
				</Link>
			</div>

			{galleryImages?.length > 0 ? (
				<Swiper
					pagination={{
						dynamicBullets: true,
					}}
					modules={[Pagination, Navigation]}
					className='mySwiper'
					initialSlide={0}
					navigation={true}
					loop={true}
					style={{ height: computeHeight() }}
				>
					{galleryImages.map((image, index) => {
						return (
							<SwiperSlide key={index}>
								<ProgressiveImage src={image}>
									{(src, loading) =>
										loading ? (
											<p>Loading...</p>
										) : (
											<img
												src={src}
												className={`${loading ? "loading" : "loaded"}`}
												onContextMenu={(e) => e.preventDefault()}
											/>
										)
									}
								</ProgressiveImage>
							</SwiperSlide>
						);
					})}
				</Swiper>
			) : (
				<p style={{ color: COLORS.white, padding: 50, paddingBottom: 200 }}>
					Puteti vedea produsele noastre direct pe pagina de Facebook ThermoArt
					Design.{" "}
					<span>
						<a
							href='https://www.facebook.com/Ferestre.Usi.Pvc.Mobila.Lemn.Masiv.La.Comanda/'
							style={{ color: COLORS.white, fontWeight: "600" }}
						>
							Dati click aici pentru a va redirectiona spre aceasta.
						</a>
					</span>
				</p>
			)}

			<Footer />
		</div>
	);
}
