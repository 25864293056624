import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";
import { COLORS } from "../theme/constants.js";
import { BsChevronLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { RxCaretDown } from "react-icons/rx";
import { addDataIntoCache } from "../utils/helpers.js";
import images from "../theme/images.js";
import analytics from "../firebase.js";
import { logEvent } from "firebase/analytics";

export default function Header() {
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const navRef = useRef(null);
	const headerRef = useRef(null);
	const transRef = useRef(null);
	const [isMobile, setIsMobile] = useState(true);
	const [visibleTranslation, setVisibleTranslation] = useState(false);
	const visibleTransRef = useRef(visibleTranslation);

	useEffect(() => {
		checkMobile();
		window.addEventListener("resize", checkMobile);
		document.addEventListener("touchend", handleMobileMenuOutside);
		document.addEventListener("click", handleTranslationDropdownOutside);

		const handleTouchMove = (e) => {
			e.stopPropagation();
		};

		if (navRef.current) {
			navRef.current.addEventListener("touchmove", handleTouchMove);
		}

		return () => {
			window.removeEventListener("resize", checkMobile);
			document.removeEventListener("touchend", handleMobileMenuOutside);
			document.removeEventListener("click", handleTranslationDropdownOutside);
			navRef?.current?.removeEventListener("touchmove", handleTouchMove);
		};
	}, []);

	useEffect(() => {
		resetMenu();
	}, [location]);

	useEffect(() => {
		visibleTransRef.current = visibleTranslation;
	}, [visibleTranslation]);

	const changeLanguage = (lang) => {
		addDataIntoCache("language", window.location.origin, lang);
		logEvent(analytics, `change_lang: ${lang}`);
		i18n.changeLanguage(lang);
		setVisibleTranslation(false);
	};

	const checkMobile = () => {
		if (window.innerWidth <= 1300) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	const resetMenu = () => {
		if (isMobile) {
			document.querySelector("nav").classList.remove("open-menu");
			setOpen(false);
			setVisibleTranslation(false);
			document
				.querySelectorAll(".submenu")
				.forEach((item) => item.classList.remove("open-menu"));
		} else if (!isMobile) {
			handleMouseLeaveSubmenu();
		}
	};

	const handleMouseEnterSubmenu = (e) => {
		e.currentTarget.querySelector(".submenu").classList.add("submenu-grid");
	};

	const handleMouseLeaveSubmenu = (e) => {
		document
			.querySelectorAll(".submenu")
			.forEach((item) => item.classList.remove("submenu-grid"));
	};

	const handleMobileMenuOutside = (event) => {
		if (
			navRef.current &&
			!navRef.current.contains(event.target) &&
			headerRef &&
			!headerRef.current.contains(event.target)
		) {
			resetMenu();
		}
	};

	const handleTranslationDropdownOutside = (event) => {
		if (
			visibleTransRef.current &&
			transRef.current &&
			!transRef.current.contains(event.target)
		) {
			setVisibleTranslation(false);
		}
	};

	const handleMobileSubmenu = (e) => {
		const selector = `#submenu-${e.target.id}`;
		document.querySelector(selector).classList.toggle("open-menu");
	};

	const openMobileMenu = (param) => {
		setOpen(param);
		document.querySelector("nav").classList.toggle("open-menu");
	};

	const closeMobileSubmenu = (e) => {
		Array.from(document.querySelectorAll(".submenu")).forEach((item) =>
			item.classList.remove("open-menu")
		);
	};

	const goToContact = () => {
		window.open("/contact", "_blank");
	};

	const renderTranslationMenu = () => (
		<div className='translation-container' ref={transRef}>
			<div
				className='translation-show'
				onClick={() => setVisibleTranslation(!visibleTranslation)}
			>
				<p>{i18n.language}</p>
				<RxCaretDown
					className='translation-arrow'
					style={visibleTranslation ? { transform: `scale(-1)` } : {}}
				/>
			</div>
			<div
				className='translation-dropdown shadow'
				style={visibleTranslation ? { display: "block" } : {}}
			>
				{Object.keys(i18n.options.resources).map((item, index) =>
					item !== i18n.language ? (
						<p key={`${index}-${item}`} onClick={() => changeLanguage(item)}>
							{item}
						</p>
					) : (
						<></>
					)
				)}
			</div>
		</div>
	);

	return (
		<header ref={headerRef}>
			{/* logo */}
			<NavLink to='/' className='header-logo'>
				<img src={images.logo} alt={"ThermoArt Design logo"} />
			</NavLink>

			{isMobile ? (
				<nav ref={navRef} className='menu'>
					<NavLink to='/' className='header-link link-text'>
						{t("header.home")}
					</NavLink>

					<div
						className={`header-link ${
							location.pathname.includes("pvc-alu") ? "active-link" : ""
						}`}
					>
						<p
							className='link-text'
							id='header-pvc'
							onClick={handleMobileSubmenu}
						>
							{t("header.pvcAluminum")}
						</p>
					</div>
					<div className='submenu' id='submenu-header-pvc'>
						<div
							className={`submenu-header ${
								location.pathname.includes("pvc") ? "active" : ""
							}`}
							onClick={closeMobileSubmenu}
						>
							<BsChevronLeft color={COLORS.black} />
							<p className='link-text'>{t("header.pvcAluminum")}</p>
						</div>

						<div className='submenu-section'>
							<p className='submenu-section-title'>{t("header.windows")}</p>
							<NavLink to='pvc-alu/windows/gealan'>Gealan</NavLink>
							<NavLink to='pvc-alu/windows/rehau'>Rehau</NavLink>
						</div>

						<div className='submenu-section'>
							<p className='submenu-section-title'>{t("header.doors")}</p>
							<NavLink to='pvc-alu/pvc-doors/exterior'>
								{t("header.exteriorDoors")}
							</NavLink>
							<NavLink to='pvc-alu/pvc-doors/interior'>
								{t("header.interiorDoors")}
							</NavLink>
							<NavLink to='pvc-alu/pvc-doors/sliding'>
								{t("header.slidingDoors")}
							</NavLink>
						</div>

						<div className='submenu-section'>
							<p className='submenu-section-title'>{t("header.others")}</p>
							<NavLink to='pvc-alu/glass'>{t("header.glass")}</NavLink>
							<NavLink to='pvc-alu/rollers'>{t("header.rollers")}</NavLink>
							<NavLink to='pvc-alu/garage-doors'>
								{t("header.garageDoors")}
							</NavLink>
						</div>

						<div className='submenu-section'>
							<p className='submenu-section-title'>{t("header.aluminum")}</p>
							<NavLink to='pvc-alu/aluminum-joinery'>
								{t("header.aluminumJoinery")}
							</NavLink>
						</div>
					</div>

					<div
						className={`header-link ${
							location.pathname.includes("wood") ? "active-link" : ""
						}`}
					>
						<p
							className='link-text'
							id='header-furniture'
							onClick={handleMobileSubmenu}
						>
							{t("header.furnitureDoors")}
						</p>
					</div>
					<div className='submenu' id='submenu-header-furniture'>
						<div
							className={`submenu-header ${
								location.pathname.includes("wood") ? "active" : ""
							}`}
							onClick={closeMobileSubmenu}
						>
							<BsChevronLeft color={COLORS.black} />
							<p className='link-text'>{t("header.furnitureDoors")}</p>
						</div>

						<div className='submenu-section'>
							<p className='submenu-section-title'>{t("header.furniture")}</p>
							<NavLink to='wood/furniture/mdf'>
								{t("header.mdfFurniture")}
							</NavLink>
							<NavLink to='wood/furniture/pal'>
								{t("header.palFurniture")}
							</NavLink>
						</div>

						<div className='submenu-section'>
							<p className='submenu-section-title'>{t("header.doors")}</p>
							<NavLink to='wood/doors/wood'>{t("header.woodDoors")}</NavLink>
							<NavLink to='wood/doors/mdf'>{t("header.mdfDoors")}</NavLink>
						</div>
					</div>

					<NavLink
						to='careers'
						className='header-link link-text'
						id='header-careers'
					>
						{t("header.careers")}
					</NavLink>

					<NavLink
						to='partners'
						className='header-link link-text'
						id='header-partners'
					>
						{t("header.partners")}
					</NavLink>

					<NavLink
						to='contact'
						className='header-link link-text'
						id='header-contact'
					>
						{t("header.contact")}
					</NavLink>

					<button
						className='header-cta'
						id='mobile-header-cta'
						onClick={goToContact}
					>
						{t("interface.wantOffer")}
					</button>

					{renderTranslationMenu()}
				</nav>
			) : (
				// DESKTOP MENU ///////////////////////////////////////////////////////////////////////////////
				<nav ref={navRef} className='menu'>
					<NavLink to='/' className='header-link link-text'>
						{t("header.home")}
					</NavLink>

					<div
						id='header-pvc'
						className={`header-link ${
							location.pathname.includes("pvc-alu") ? "active-link" : ""
						}`}
						onMouseEnter={handleMouseEnterSubmenu}
						onMouseLeave={handleMouseLeaveSubmenu}
					>
						<p className='link-text' id='header-pvc-link'>
							{t("header.pvcAluminum")}
						</p>

						<div className='submenu'>
							<div className='submenu-section'>
								<p className='submenu-section-title'>{t("header.windows")}</p>
								<NavLink to='pvc-alu/windows/gealan'>Gealan</NavLink>
								<NavLink to='pvc-alu/windows/rehau'>Rehau</NavLink>
							</div>

							<div className='submenu-section'>
								<p className='submenu-section-title'>{t("header.doors")}</p>
								<NavLink to='pvc-alu/pvc-doors/exterior'>
									{t("header.exteriorDoors")}
								</NavLink>
								<NavLink to='pvc-alu/pvc-doors/interior'>
									{t("header.interiorDoors")}
								</NavLink>
								<NavLink to='pvc-alu/pvc-doors/sliding'>
									{t("header.slidingDoors")}
								</NavLink>
							</div>

							<div className='submenu-section'>
								<p className='submenu-section-title'>{t("header.others")}</p>
								<NavLink to='pvc-alu/glass'>{t("header.glass")}</NavLink>
								<NavLink to='pvc-alu/rollers'>{t("header.rollers")}</NavLink>
								<NavLink to='pvc-alu/garage-doors'>
									{t("header.garageDoors")}
								</NavLink>
							</div>

							<div className='submenu-section'>
								<p className='submenu-section-title'>{t("header.aluminum")}</p>
								<NavLink to='pvc-alu/aluminum-joinery'>
									{t("header.aluminumJoinery")}
								</NavLink>
							</div>
						</div>
					</div>

					<div
						id='header-furniture'
						className={`header-link ${
							location.pathname.includes("wood") ? "active-link" : ""
						}`}
						onMouseEnter={handleMouseEnterSubmenu}
						onMouseLeave={handleMouseLeaveSubmenu}
					>
						<p className='link-text' id='header-furniture-link'>
							{t("header.furnitureDoors")}
						</p>

						<div className='submenu'>
							<div className='submenu-section'>
								<p className='submenu-section-title'>{t("header.furniture")}</p>
								<NavLink to='wood/furniture/mdf'>
									{t("header.mdfFurniture")}
								</NavLink>
								<NavLink to='wood/furniture/pal'>
									{t("header.palFurniture")}
								</NavLink>
							</div>

							<div className='submenu-section'>
								<p className='submenu-section-title'>{t("header.doors")}</p>
								<NavLink to='wood/doors/wood'>{t("header.woodDoors")}</NavLink>
								<NavLink to='wood/doors/mdf'>{t("header.mdfDoors")}</NavLink>
							</div>
						</div>
					</div>

					<NavLink
						to='careers'
						className='header-link link-text'
						id='header-careers'
					>
						{t("header.careers")}
					</NavLink>

					<NavLink
						to='partners'
						className='header-link link-text'
						id='header-partners'
					>
						{t("header.partners")}
					</NavLink>

					<NavLink
						to='contact'
						className='header-link link-text'
						id='header-contact'
					>
						{t("header.contact")}
					</NavLink>
				</nav>
			)}

			{/* HEADER BUTTONS */}
			<button className='cta header-cta' onClick={goToContact}>
				{t("interface.wantOffer")}
			</button>

			<div id='hamburger'>
				<Hamburger
					toggled={isOpen}
					toggle={openMobileMenu}
					color={COLORS.black}
				/>
			</div>

			{!isMobile ? renderTranslationMenu() : <></>}
		</header>
	);
}
