import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function ProductSwitch({ transKey, subcategory }) {
	const { t } = useTranslation();

	return subcategory?.products ? (
		<div className='switch-container' id='products-switch'>
			<div className='switch'>
				{subcategory.products.map((item, index) => (
					<NavLink
						to={item}
						key={index}
						className='option'
						style={
							index === 0
								? { borderWidth: 0, paddingLeft: 40 }
								: index === subcategory.products.length - 1
								? { paddingRight: 40 }
								: {}
						}
					>
						<p>{t(`products.${transKey}.${item}.name`)}</p>
					</NavLink>
				))}
			</div>
		</div>
	) : (
		<></>
	);
}
