import React, { useEffect,  useState } from 'react'
import { differenceInYears, differenceInMonths } from "date-fns";
import { roundNearest100 } from '../utils/helpers'
import Counter from '../components/Counter'
import { useTranslation } from 'react-i18next'
import ContactButton from '../components/ContactButton'
import ProgressiveImage from 'react-progressive-graceful-image'
import images from '../theme/images';

const STATISTICS_BASE = {
  experience: {
    label: 'years',
    initialData: new Date('2002-01-01'),
  },
  clients: {
    label: 'clients',
    initialData: 60, //clients / month
  },
  orders: {
    label: 'orders',
    initialData: 90, //orders / month
  },
}

export default function Home() {

  const {t} = useTranslation()
  const [statistics, setStatistics] = useState([])
  const [counterCanStart, setCounterCanStart] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)

    let stats = []
    //COMPUTE TOTAL YEARS
    let currYearsExp = differenceInYears(new Date(), STATISTICS_BASE.experience.initialData)
    //COMPUTE TOTAL MONTHS
    let currMonthsExp = differenceInMonths(new Date(), STATISTICS_BASE.experience.initialData)
    //COMPUTE TOTAL CLIENTS (clientsNo/month * totalMonths)
    let currClients = currMonthsExp * STATISTICS_BASE.clients.initialData
    //COMPUTE TOTAL ORDERS (idem)
    let currOrders = currMonthsExp * STATISTICS_BASE.orders.initialData
    currClients = roundNearest100(currClients)
    currOrders = roundNearest100(currOrders)

    stats = [currYearsExp, currClients, currOrders]

    let currStatistics = Object.entries(STATISTICS_BASE).map((item, index) => (
      {
        label: item[1].label,
        data: stats[index],
      }
    ))

    setStatistics(currStatistics)
  }, [])

  const renderStats = () => (
    <div className='statistics-container' id='home-stats' >
      {
        statistics.map((item, index) => (
          <div className='stats-item' key={index}>
            <p className='stats-label stats-prefix'>{t('homepage.counter.over')}</p>
            <Counter to={item.data} className={'counter'} canStart={counterCanStart} />
            <p className='stats-label'>{t(`homepage.counter.${item.label}`)}</p>
          </div>
        ))
      }
    </div>
  )

  return (
    <div id='home-container'>
      
      <div className='hero-section' id='home-hero'>
        <div className='hero-image-container' id='image-1-container' >
          <ProgressiveImage src={require('../assets/tamplarie-pvc.jpg')} placeholder={require('../assets/tamplarie-pvc-placeholder.jpg')}>
            {(src, loading) =>
              <img src={src} className={`home-hero-image ${loading ? 'loading' : 'loaded'}`} alt="tamplarie pvc aluminiu" onLoad={() => setCounterCanStart(true)} />
            }
          </ProgressiveImage>
          <div className='hero-text-container'>
            <h1>{t('homepage.hero_pvc')}</h1>
          </div>
        </div>
       
        <div className='hero-image-container' id='image-2-container'>
          <ProgressiveImage src={require('../assets/mobila.jpg')} placeholder={require('../assets/mobila-placeholder.jpg')}>
            {(src, loading) =>
              <img src={src} className={`home-hero-image ${loading ? 'loading' : 'loaded'}`} alt="mobila mdf pal" />
            }
          </ProgressiveImage>
          <div className='hero-text-container'>
            <h1>{t('homepage.hero_lemn')}</h1>
          </div>
        </div>
      </div>

      <div className='about-us'>
        <img src={images.logo_sign} alt={'ThermoArt Design logo'} />
        <h2 className='section-title'>{t('homepage.about_us')}</h2>
        <p>{t('homepage.about_us_content')}</p>
      </div>

      {renderStats()}

      <div className='discover-us'>
        <h2 className='section-title'>{t('homepage.discover')}</h2>
        <p>{t('homepage.discover_content')}</p>
        <ContactButton text={t('interface.wantOffer')} />
      </div>

    </div>
  )
}
